import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import DialogSchimbaNumeClasa from "./popUpSchimbaNumeClasa";
import DialogSchimbaDirig from "./popUpSchimbaDirig";

function MakeCardGoToSali() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const manageOnClickSali = () => {
    navigate("/sali");
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "6px"}}>
            <h3 className="letterStyle">Săli de curs</h3>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            gap: "3px",
            paddingRight: "17px"
          }}
        >
            <div
                style={{display: "flex", flexWrap: "wrap", gap: "3px"}}>
                <Button onClick = {manageOnClickSali} variant="contained" color="success"><p className="letterStyle">Vezi săli</p></Button>
            </div>
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardGoToSali;
