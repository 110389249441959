import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import "../cssFiles/basicStyles.css"
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { Button } from "@mui/material";
import MakeCardProgram from "./boxProgram";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function InfotainmentProgram(props) {
  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const [program, changeProgram] = React.useState([]);
  const zile = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri'];

  const sortVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let xZi = a.zi;
      let yZi = b.zi;

      if ( xZi > yZi ) {
        return 1;
      }
      if ( xZi < yZi ) {
        return -1;
      }

      let xIndexOra = a.timpinceputora;
      let yIndexOra = b.timpinceputora;

      if ( xIndexOra > yIndexOra ) {
        return 1;
      }
      if ( xIndexOra < yIndexOra ) {
        return -1;
      }

      let xIndexMinut = a.timpinceputminut;
      let yIndexMinut = b.timpinceputminut;

      if ( xIndexMinut > yIndexMinut ) {
        return 1;
      }
      if ( xIndexMinut < yIndexMinut ) {
        return -1;
      }

      return 0;
    });

    for ( var i = 0; i < aux.length; i++ ) {
        if ( i == 0 || aux[i].zi != aux[i - 1].zi ) {
            aux[i].isFirst = true;
        } else {
            aux[i].isFirst = false;
        }
    }

    changeProgram(aux);
  }

  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
    if ( !cookies.get("_auth") ) {
      navigate("/");
    }

    async function fetchDataOrar() {
        try {
            const orar = await axios.post(
            "http://localhost:4000/api/getProgramProf", {
                token: cookies.get("_auth"),
            }
            );

            sortVar(orar.data.program);
        } catch (err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }
    
    if ( oneTime ) {
      fetchDataOrar();
      setOneTime(false);
    }
  }, [cookies, navigate]);

  function makeProgram(ora) {
    return (
      <div key = {ora.id}>
        {ora.isFirst ? 
          <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {zile[ora.zi]} </h3> 
        :
        null
        }
        <MakeCardProgram
          inceputOra = {ora.timpinceputora}
          inceputMinut = {ora.timpinceputminut}
          sfarsitOra = {ora.timpsfarsitora}
          sfarsitMinut = {ora.timpsfarsitminut}
          scoala = {ora.scoala}
          nume = {ora.numeora}
          clasa = {ora.numeclasa}
          numeSala = {ora.numesala}
        />
      </div>
    );
  }

  const goBack = () => {
    navigate(-1);
  }


  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "0px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          {program.map(makeProgram)}
        </div>
    </Container>
  );
}

export default InfotainmentProgram;