import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import DialogInfo from "./popUpInfo";
import { useNavigate } from "react-router-dom";
import DialogMotiveazaInterval from "./popUpMotiveazaInterval";
import DialogContactParinte from "./popUpContacteazaParinte";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function MakeCardElevDirector(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [showSmallInfo, setSmallInfo] = React.useState(window.innerWidth > (1000 + 500 * props.isDirig));
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const goToElev = () => {
    navigate("/elev/" + props.id);
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          paddingBottom: "5px"
        }}
      >
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%"}}>
        <div style={{ display: "flex", alignItems: "center", gap: "15px", marginBottom: "2px"}}>
          <h3 className="letterStyle">{props.nume}</h3>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "15px"
            }}
          >
          { showSmallInfo ? <h6 className="letterStyle">Medie: {props.medie}</h6> : null }
          { showSmallInfo ? <h6 className="letterStyle">Nr. absențe totale/nemotivate: <span style={{color: "green"}}>{props.nrAbsente}</span>/<span style={{color: "red"}}>{props.nrAbsenteNemotivate}</span></h6> : null }
          { props.isDirig ? 
            <DialogMotiveazaInterval id={props.id} makeRefresh={props.makeRefresh}/>
            :
            null
          }
          <Button onClick={goToElev} variant="contained" color="success">Vezi elev</Button>
          </div>
        </div>
        </div>
        <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", borderTop: "solid", paddingTop: "10px", paddingBottom: "10px", borderColor: "grey"}}>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            {!showSmallInfo ? 
              <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", paddingTop: "3px", paddingBottom: "3px"}}>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", padding: "3px 7px 3px 7px", gap: "15px"}} className="makeBorder">
                    <h6 className="letterStyle">Medie: {props.medie}</h6>
                    <h6 className="letterStyle">Nr. absențe totale/nemotivate: <span style={{color: "green"}}>{props.nrAbsente}</span>/<span style={{color: "red"}}>{props.nrAbsenteNemotivate}</span></h6>
                </div>
              </div>
              :
              null
            }
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", paddingTop: "3px", paddingBottom: "10px"}}>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "5px"}} className="makeBorder">
                  <h6 className="letterStyle">Date contact</h6>
                  <div style={{display: "flex", justifyContent: "center", gap: "15px", flexWrap: "wrap"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <p className="letterStyle">Email: {props.email} </p>
                        <p className="letterStyle">Telefon: {props.tel} </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div style={{display: "flex", justifyContent: "end", marginRight: "10px"}}>
            <DialogContactParinte 
              idOra = {-1}
              idElev = {props.id}
            />
          </div>
        </div>
      </Collapse>
    </Card>
    </Container>
  );
}

export default MakeCardElevDirector;
