import React from "react";
import Button from '@mui/material/Button';
import "../cssFiles/styles.css"
import { Link } from "react-router-dom";

function MakeButton(props) {
    return (
        <Link to={props.loc}><Button variant="text" color="inherit"><p className="textInHeader">{props.text}</p></Button></Link>
    );
}

export default MakeButton;