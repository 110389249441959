import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeTab from "./pages/Home";
import LogInTab from "./pages/LogIn";
import MainTab from "./pages/Main";
import SetariTab from "./pages/Setari";
import ElevTab from "./pages/Elev";
import ClasaTab from "./pages/Clasa";
import PrezentaTab from "./pages/Prezenta";
import ContactTab from "./pages/Contact";
import ServiciiTab from "./pages/Servicii";
import IstoricTab from "./pages/Istoric";
import ClasaDirectorTab from "./pages/ClasaDirector";
import OreAdminTab from "./pages/OreAdmin";
import EleviAdminTab from "./pages/EleviAdmin";
import ProfesoriAdminTab from "./pages/ProfesoriAdmin";
import OrarTab from "./pages/Orar";
import ProgramTab from "./pages/Program";
import SaliTab from "./pages/Sali";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainTab />} />
        <Route path="/login" element={<LogInTab />} />
        <Route path="/home" element={<HomeTab />} />
        <Route path="/setari" element={<SetariTab />} />
        <Route path="/elev/:id" element={<ElevTab />} />
        <Route path="/clasa/:id" element={<ClasaTab />} />
        <Route path="/prezenta/:id" element={<PrezentaTab />} />
        <Route path="/contact" element={<ContactTab />} />
        <Route path="/servicii" element={<ServiciiTab />} />
        <Route path="/istoric/:id" element={<IstoricTab />} />
        <Route path="/clasaDirector/:id" element={<ClasaDirectorTab />} />
        <Route path="/oreAdmin/:id" element={<OreAdminTab />} />
        <Route path="/eleviAdmin/:id" element={<EleviAdminTab />} />
        <Route path="/profesoriAdmin" element={<ProfesoriAdminTab />} />
        <Route path="/orarClasa/:id" element={<OrarTab />} />
        <Route path="/program" element={<ProgramTab />} />
        <Route path="/sali" element={<SaliTab />} />
        <Route path="*" element={<MainTab />} />
      </Routes>
    </BrowserRouter>
  );
}

/*
        */