import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function DialogMotiveazaInterval(props) {
  const [open, setOpen] = React.useState(false);
  const date = new Date();
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function compDates(d1, m1, y1, d2, m2, y2) { ///is date1 > date2 => true
    if ( y1 > y2 ) {
      return 1;
    }
    if ( y1 < y2 ) {
      return -1;
    }
    if ( m1 > m2 ) {
      return 1;
    }
    if ( m1 < m2 ) {
      return -1;
    }
    if ( d1 > d2 ) {
      return 1;
    }
    if ( d1 < d2 ) {
      return -1;
    }
    return 0;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const date1 = new Date(event.target.an1.value + "-" + event.target.luna1.value + "-" + event.target.zi1.value);
    const date2 = new Date(event.target.an2.value + "-" + event.target.luna2.value + "-" + event.target.zi2.value);

    if ( !isNaN(date1.getTime()) && !isNaN(date2.getTime()) ) {
      date1.setDate(date1.getDate() - 1);
      date2.setDate(date2.getDate() - 1);

      try {
        const response = await axios.post("http://localhost:4000/api/getAbsenteAll", {
          token: cookies.get("_auth"),
          idElev: props.id
        });

        console.log(props.id);

        for ( var i = 0; i < response.data.absente.length; i++ ) {
          console.log(date1.getDate(), date1.getMonth() + 1, date1.getFullYear(), response.data.absente[i].datapus.slice(8, 10), response.data.absente[i].datapus.slice(5, 7), response.data.absente[i].datapus.slice(0, 4));
          if ( compDates(date1.getDate(), date1.getMonth() + 1, date1.getFullYear(), response.data.absente[i].datapus.slice(8, 10), response.data.absente[i].datapus.slice(5, 7), response.data.absente[i].datapus.slice(0, 4)) <= 0 &&
               compDates(date2.getDate(), date2.getMonth() + 1, date2.getFullYear(), response.data.absente[i].datapus.slice(8, 10), response.data.absente[i].datapus.slice(5, 7), response.data.absente[i].datapus.slice(0, 4)) >= 0 ) {
                try {
                  await axios.post(
                    "http://localhost:4000/api/motiveazaAbsenta", {
                      token: cookies.get("_auth"),
                      idAbsenta: response.data.absente[i].id
                    });
                } catch(err) {
                  cookies.remove("_auth");
                  navigate("/");
                }
              }
        }
      } catch (err) {
        cookies.remove("_auth");
        navigate("/");
      }
    }

    props.makeRefresh();
    handleClose();
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} variant="contained" color="success"><p className="letterStyle">Motivează absențe</p></Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Motivează absențe</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmit}>
              <h4 className='letterStyle' style={{marginBottom: "5px", color: "black"}}>De la</h4>
              <div style={{display: "flex", gap: "3px"}}>
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="zi1"
                      label="Zi"
                      defaultValue={date.getDate()}
                  />
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Luna"
                      name="luna1"
                      defaultValue={date.getMonth() + 1}
                  />
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="An"
                      name="an1"
                      defaultValue={date.getFullYear()}
                  />
              </div>
              <h4 className='letterStyle' style={{marginTop: "5px", marginBottom: "5px", color: "black"}}>Până la</h4>
              <div style={{display: "flex", gap: "3px"}}>
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="zi2"
                      label="Zi"
                      defaultValue={date.getDate()}
                  />
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Luna"
                      name="luna2"
                      defaultValue={date.getMonth() + 1}
                  />
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="An"
                      name="an2"
                      defaultValue={date.getFullYear()}
                  />
              </div>
              <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                  <Button onClick={handleClose} variant='text'>Închide</Button>
                  <Button type="submit" variant="contained"> Ok </Button>
              </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}