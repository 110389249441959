import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MakeCardProfPentruElev from "./colidbleBoxProf";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";

function InfotainmentProf(props) {
  var v = [];
  const [typeOfSort, setTypeSort] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const {id} = useParams();
  const [elevi, setElevi] = React.useState([]);

  const sortAlfabetic = () => {
    var aux = elevi;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setTypeSort(0);
    setElevi(aux);

    handleClose();
  }

  const sortAlfabeticVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setTypeSort(0);
    setElevi(aux);

    handleClose();
  }

  const sortMedieVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
        var medieA = 0;
        if ( a.note.length ) {
          medieA = Math.round(a.sumaelev / a.note.length);
        }

        var medieB = 0;
        if ( b.note.length ) {
          medieB = Math.round(b.sumaelev / b.note.length);
        }

        if ( medieA > medieB ) {
          return 1;
        }
        if ( medieB > medieA ) {
          return -1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    setElevi(aux);
    setTypeSort(1);

    handleClose();
  }

  const sortAbsenteVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
        var absenteA = a.absente.length;

        var absenteB = b.absente.length;

        if ( absenteA > absenteB ) {
          return -1;
        }
        if ( absenteB > absenteA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    setElevi(aux);
    setTypeSort(2);

    handleClose();
  }

  const sortNoteNecesareVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
        var nrNecesarA = Math.max(0, a.nrore - a.note.length);

        var nrNecesarB = Math.max(0, b.nrore - b.note.length);

        if ( nrNecesarA > nrNecesarB ) {
          return -1;
        }
        if ( nrNecesarB > nrNecesarA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    setElevi(aux);
    setTypeSort(3);

    handleClose();
  }

  const sortMedie = () => {
    var aux = elevi;

    aux.sort((a, b) => {
        var medieA = 0;
        if ( a.note.length ) {
          medieA = Math.round(a.sumaelev / a.note.length);
        }

        var medieB = 0;
        if ( b.note.length ) {
          medieB = Math.round(b.sumaelev / b.note.length);
        }

        if ( medieA > medieB ) {
          return 1;
        }
        if ( medieB > medieA ) {
          return -1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    setElevi(aux);
    setTypeSort(1);

    handleClose();
  }

  const sortAbsente = () => {
    var aux = elevi;

    aux.sort((a, b) => {
        var absenteA = a.absente.length;

        var absenteB = b.absente.length;

        if ( absenteA > absenteB ) {
          return -1;
        }
        if ( absenteB > absenteA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    setElevi(aux);
    setTypeSort(2);

    handleClose();
  }

  const sortNoteNecesare = () => {
    var aux = elevi;

    aux.sort((a, b) => {
        var nrNecesarA = Math.max(0, a.nrore - a.note.length);

        var nrNecesarB = Math.max(0, b.nrore - b.note.length);

        if ( nrNecesarA > nrNecesarB ) {
          return -1;
        }
        if ( nrNecesarB > nrNecesarA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    setElevi(aux);
    setTypeSort(3);

    handleClose();
  }

  function makeCallback(x) {
    v.push(x);
  }

  function onPress() {
    var i;

    for ( i = 0; i < v.length; i++ ) {
        v[i](true);
    }
  }

  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
      if ( !cookies.get("_auth") ) {
          navigate("/");
      }

      async function testIntegrity() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getClasa", {
                    token: cookies.get("_auth"),
                    idOra: id
                }
            );

            var x = response.data.dateElevi;
            for ( var i = 0; i < x.length; i++ ) {
              x[i].absente.sort((a, b) => {
                const dataX = a.datapus;
                const dataY = b.datapus;
  
                if ( dataX > dataY ) {
                  return -1;
                }
                if ( dataX < dataY ) {
                  return 1;
                }
                return 0;
              });
              x[i].note.sort((a, b) => {
                const dataX = a.datapus;
                const dataY = b.datapus;
  
                if ( dataX > dataY ) {
                  return -1;
                }
                if ( dataX < dataY ) {
                  return 1;
                }
                return 0;
              });
            }
            sortAlfabeticVar(x);
        } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    if ( oneTime ) {
      testIntegrity();
      setOneTime(false);
    }
    }, [cookies, navigate, id]);

    const sortNice = (x) => {
      if ( typeOfSort == 0 ) {
        sortAlfabeticVar(x);
      } else if ( typeOfSort == 1 ) {
        sortMedieVar(x);
      } else if ( typeOfSort == 2 ) {
        sortAbsenteVar(x);
      } else {
        sortNoteNecesareVar(x);
      }
    }
    
    const refreshInfo = async () => {
      try {
          const response = await axios.post(
              "http://localhost:4000/api/getClasa", {
                  token: cookies.get("_auth"),
                  idOra: id
              }
          );
  
          var x = response.data.dateElevi;
          for ( var i = 0; i < x.length; i++ ) {
            x[i].absente.sort((a, b) => {
              const dataX = a.datapus;
              const dataY = b.datapus;

              if ( dataX > dataY ) {
                return -1;
              }
              if ( dataX < dataY ) {
                return 1;
              }
              return 0;
            });
            x[i].note.sort((a, b) => {
              const dataX = a.datapus;
              const dataY = b.datapus;

              if ( dataX > dataY ) {
                return -1;
              }
              if ( dataX < dataY ) {
                return 1;
              }
              return 0;
            });
          }
          sortNice(x);
      } catch(err) {
          cookies.remove("_auth");
          navigate("/");
      }
    }

    function makeCardForElev(elev) {
      var medie = "-";
      if ( elev.note.length ) {
        medie = Math.round(elev.sumaelev / elev.note.length);
      }

      var absenteElev = "-";
      if ( elev.absente.length ) {
        absenteElev = elev.absente.length;
      }

      var nrAbsenteNemotivate = "-";
      if ( elev.absenteNemotivate ) {
        nrAbsenteNemotivate = elev.absenteNemotivate;
      }

      var nrNote = "-";
      if ( elev.note.length ) {
        nrNote = elev.note.length;
      }

      return (
        <MakeCardProfPentruElev
            key = {elev.id}
            id = {elev.id}
            idOra = {id}
            nume = {elev.nume}
            makeCallback = {makeCallback}
            numeMaterie = {elev.numeMaterie}
            medie = {medie}
            nrNote = {nrNote}
            nrAbsente = {absenteElev}
            nrAbsenteNemotivate = {nrAbsenteNemotivate}
            note = {elev.note}
            absente = {elev.absente}
            noteNecesare = {Math.max(0, elev.nrore - elev.note.length)}
            makeRefresh = {refreshInfo}
            email = {elev.mail}
            tel = {elev.tel}
        />
      );
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "13px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
          <div style={{display: "flex", justifyContent: "end", gap: "5px"}}>
            <Button onClick={onPress} variant="outlined">Deschide tot</Button>
            <Button onClick={handleClick} variant="text">Sortează dupa<ExpandMoreIcon /></Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={sortAlfabetic}>Alfabetic</MenuItem>
              <MenuItem onClick={sortMedie}>Medie</MenuItem>
              <MenuItem onClick={sortAbsente}>Nr. absențe</MenuItem>
              <MenuItem onClick={sortNoteNecesare}>Nr. de note necesare</MenuItem>
            </Menu>
          </div>
        </div>
        {elevi.map(makeCardForElev)}
    </Container>
  );
}

export default InfotainmentProf;
