import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MakeCardProfPentruElev from "./colidbleBoxProf";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import MakeCardElevDirector from "./boxElevDirector";

function InfotainmentDirector(props) {
  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const {id} = useParams();
  const [elevi, setElevi] = React.useState([]);
  const [isDirig, setIsDirig] = React.useState(false);

  const sortAlfabeticVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setElevi(aux);
  }

  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
      if ( !cookies.get("_auth") ) {
          navigate("/");
      }

      async function testIntegrity() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getClasaDirector", {
                    token: cookies.get("_auth"),
                    idClasa: id
                }
            );

            setIsDirig(response.data.isDirig);
            var x = response.data.dateElevi;
            sortAlfabeticVar(x);
        } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    if ( oneTime ) {
      testIntegrity();
      setOneTime(false);
    }
    }, [cookies, navigate]);

    async function makeRefresh() {
      try {
        const response = await axios.post(
            "http://localhost:4000/api/getClasaDirector", {
                token: cookies.get("_auth"),
                idClasa: id
            }
        );

        setIsDirig(response.data.isDirig);
        var x = response.data.dateElevi;
        sortAlfabeticVar(x);
      } catch(err) {
          cookies.remove("_auth");
          navigate("/");
      }
    }

    function makeCardForElev(elev) {
      return (
        <MakeCardElevDirector
            key = {elev.id}
            id = {elev.id}
            nume = {elev.nume}
            medie = {Math.round(100 * (elev.sumamaterii + elev.notapurtare) / elev.nrmaterii) / 100}
            nrAbsente = {elev.nrAbsente}
            nrAbsenteNemotivate = {elev.nrAbsenteNemotivate}
            email = {elev.mail}
            tel = {elev.tel}
            isDirig = {isDirig}
            makeRefresh = {makeRefresh}
        />
      );
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "13px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
        </div>
        {elevi.map(makeCardForElev)}
    </Container>
  );
}

export default InfotainmentDirector;
