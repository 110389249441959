import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie"
import "../cssFiles/basicStyles.css";
import { Button, Container } from "@mui/material";
import axios from "axios";

function MainTab() {
    const cookies = React.useMemo(() => {return new Cookies()}, []);
    const navigate = useNavigate();
    const [showSpace, setShowSpacce] = React.useState(window.innerWidth > 700)

    React.useEffect(() => {
      if ( cookies.get("_auth") ) {
        navigate("/home");
      }
    }, [cookies, navigate]);

    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <Container>
          {showSpace ? 
            <div style={{marginTop: "20vh"}}></div> : null}
          <div style={{display: "flex", flexDirection: "column", gap: "15px", marginBottom: "5px", marginTop: "2vh"}}>
          <h2 className="letterStyle" style={{marginBottom: "10px"}}>Despre noi</h2>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Suntem o echipă <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>DEVOTATĂ</span> de programatori ce este capabilă să contribuie la <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>TEHNOLOGIZAREA</span> sistemului românesc de învățământ.</p>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Prin acest proiect ne propunem să facilitam școlilor un catalog virtual ușor de utilizat, <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>INTUITIV</span>, care să <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>EFICIENTIZEZE</span> procesul de completare a rapoartelor și de atribuire a notelor și absențelor.</p>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Am creat o platformă bine <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>SECURIZATĂ</span>, pregatită pentru utilizarea <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>DIRECTĂ</span> atât de către profesori, cât și de către elevi.</p>
          </div>
        </Container>
        <MakeFooter />
      </div>
    );
}
///<img src={pozaCatalog} alt="Poza catalog" style={{width: "300px"}}/>
export default MainTab;
   