import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import MakeCard from "./colidbleBox";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card } from "@mui/material";

function InfotainmentElev(props) {
  var v = [];
  const [typeOfSort, setTypeSort] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [nrAbsente, setNrAbsente] = React.useState(0);
  const [nrAbsenteNemotivate, setNrAbsenteNemotivate] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [materii, changeMaterii] = React.useState([]);
  const [hasRight, changeRight] = React.useState(false);
  const [idDirig, setIdDirig] = React.useState(-1);
  const [notaPurtare, setNotaPurtare] = React.useState(0);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortAlfabeticVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setTypeSort(0);
    changeMaterii(aux);

    handleClose();
  }

  const sortMedieVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
        var medieA = 0;
        if ( a.note.length ) {
          medieA = Math.round(a.sumaelev / a.note.length);
        }

        var medieB = 0;
        if ( b.note.length ) {
          medieB = Math.round(b.sumaelev / b.note.length);
        }

        if ( medieA > medieB ) {
          return 1;
        }
        if ( medieB > medieA ) {
          return -1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    changeMaterii(aux);
    setTypeSort(1);

    handleClose();
  }

  const sortAbsenteVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
        var absenteA = a.absente.length;

        var absenteB = b.absente.length;

        if ( absenteA > absenteB ) {
          return -1;
        }
        if ( absenteB > absenteA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    changeMaterii(aux);
    setTypeSort(2);

    handleClose();
  }

  const sortNoteNecesareVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
        var nrNecesarA = Math.max(0, a.nrore - a.note.length);

        var nrNecesarB = Math.max(0, b.nrore - b.note.length);

        if ( nrNecesarA > nrNecesarB ) {
          return -1;
        }
        if ( nrNecesarB > nrNecesarA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    changeMaterii(aux);
    setTypeSort(3);

    handleClose();
  }

  const sortAlfabetic = () => {
    var aux = materii;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setTypeSort(0);
    changeMaterii(aux);

    handleClose();
  }

  const sortMedie = () => {
    var aux = materii;

    aux.sort((a, b) => {
        var medieA = 0;
        if ( a.note.length ) {
          medieA = Math.round(a.sumaelev / a.note.length);
        }

        var medieB = 0;
        if ( b.note.length ) {
          medieB = Math.round(b.sumaelev / b.note.length);
        }

        if ( medieA > medieB ) {
          return 1;
        }
        if ( medieB > medieA ) {
          return -1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    changeMaterii(aux);
    setTypeSort(1);

    handleClose();
  }

  const sortAbsente = () => {
    var aux = materii;

    aux.sort((a, b) => {
        var absenteA = a.absente.length;

        var absenteB = b.absente.length;

        if ( absenteA > absenteB ) {
          return -1;
        }
        if ( absenteB > absenteA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    changeMaterii(aux);
    setTypeSort(2);

    handleClose();
  }

  const sortNoteNecesare = () => {
    var aux = materii;

    aux.sort((a, b) => {
        var nrNecesarA = Math.max(0, a.nrore - a.note.length);

        var nrNecesarB = Math.max(0, b.nrore - b.note.length);

        if ( nrNecesarA > nrNecesarB ) {
          return -1;
        }
        if ( nrNecesarB > nrNecesarA ) {
          return 1;
        }

        let x = a.nume;
        let y = b.nume;
        if ( x > y ) {
          return 1;
        }
        if ( y > x ) {
          return -1;
        }
        return 0;
    });

    changeMaterii(aux);
    setTypeSort(3);

    handleClose();
  }

  function makeCallback(x) {
    v.push(x);
  }

  function onPress() {
    var i;

    for ( i = 0; i < v.length; i++ ) {
        v[i](true);
    }
  }

  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const {id} = useParams();
  const [medieGenerala, setMedieGenerala] = React.useState(0);
  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
      if ( !cookies.get("_auth") ) {
          navigate("/");
      }

      async function testIntegrity() {
          try {
              const response = await axios.post(
                  "http://localhost:4000/api/getElev", {
                      token: cookies.get("_auth"),
                      idElev: id
                  }
              );

              setNrAbsente(response.data.nrAbsente);
              setNrAbsenteNemotivate(response.data.nrAbsenteNemotivate);
              setIdDirig(response.data.idDirig);
              changeRight(response.data.isGood);
              setMedieGenerala(response.data.medieGenerala);
              setOneTime(false);
              var x = response.data.ore;
              for ( var i = 0; i < x.length; i++ ) {
                x[i].absente.sort((a, b) => {
                  const dataX = a.datapus;
                  const dataY = b.datapus;

                  if ( dataX > dataY ) {
                    return -1;
                  }
                  if ( dataX < dataY ) {
                    return 1;
                  }
                  return 0;
                });
                x[i].note.sort((a, b) => {
                  const dataX = a.datapus;
                  const dataY = b.datapus;

                  if ( dataX > dataY ) {
                    return -1;
                  }
                  if ( dataX < dataY ) {
                    return 1;
                  }
                  return 0;
                });
              }
              sortAlfabeticVar(x);
              setNotaPurtare(response.data.notaPurtare);
          } catch(err) {
              cookies.remove("_auth");
              navigate("/");
          }
      }

      if ( oneTime ) {
        testIntegrity();
      }
    }, [cookies, navigate, sortAlfabeticVar]);

    const sortNice = (x) => {
      if ( typeOfSort == 0 ) {
        sortAlfabeticVar(x);
      } else if ( typeOfSort == 1 ) {
        sortMedieVar(x);
      } else if ( typeOfSort == 2 ) {
        sortAbsenteVar(x);
      } else {
        sortNoteNecesareVar(x);
      }
    }

  const refreshInfo = async () => {
    try {
        const response = await axios.post(
            "http://localhost:4000/api/getElev", {
                token: cookies.get("_auth"),
                idElev: id
            }
        );

        setNrAbsente(response.data.nrAbsente);
        setNrAbsenteNemotivate(response.data.nrAbsenteNemotivate);
        changeRight(response.data.isGood);
        setMedieGenerala(response.data.medieGenerala);
        var x = response.data.ore;
        for ( var i = 0; i < x.length; i++ ) {
          x[i].absente.sort((a, b) => {
            const dataX = a.datapus;
            const dataY = b.datapus;

            if ( dataX > dataY ) {
              return -1;
            }
            if ( dataX < dataY ) {
              return 1;
            }
            return 0;
          });
          x[i].note.sort((a, b) => {
            const dataX = a.datapus;
            const dataY = b.datapus;

            if ( dataX > dataY ) {
              return -1;
            }
            if ( dataX < dataY ) {
              return 1;
            }
            return 0;
          });
        }
        sortNice(x);
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  function makeMaterie(materie) {
      var medie = "-";
      if ( materie.note.length ) {
        medie = Math.round(materie.sumaelev / materie.note.length);
      }

      var medieClasa = "-";
      if ( materie.nrnote ) {
        medieClasa = Math.round((materie.sumanote / materie.nrnote) * 100) / 100;
      }

      var absenteElev = "-";
      if ( materie.absente.length ) {
        absenteElev = materie.absente.length;
      }

      var absenteClasa = "-";
      if ( materie.nrabsente ) {
        absenteClasa = Math.round((materie.nrabsente / materie.nreleviclasa) * 100) / 100;
      }

      var nrNoteClasa = "-";
      if ( materie.nrnote ) {
        nrNoteClasa = Math.round((materie.nrnote / materie.nreleviclasa) * 100) / 100;
      }

      var absenteScoala = "-";
      if ( materie.nrabsentescoala ) {
        absenteScoala = Math.round((materie.nrabsentescoala / materie.nreleviscoala) * 100) / 100;
      }

      var medieScoala = "-";
      if ( materie.sumanotescoala ) {
        medieScoala = Math.round((materie.sumanotescoala / materie.nrnotescoala) * 100) / 100;
      }

      var nrNoteScoala = "-";
      if ( materie.nrnotescoala ) {
        nrNoteScoala = Math.round((materie.nrore * materie.nrnotescoala / materie.nreleviscoala) * 100) / 100;
      }

      var nrNote = "-";
      if ( materie.note.length ) {
        nrNote = materie.note.length;
      }

      var nrAbsenteNemotivateMaterie = "-";
      if ( materie.nrAbsenteNemotivate ) {
        nrAbsenteNemotivateMaterie = materie.nrAbsenteNemotivate;
      }

      return (
      <MakeCard
          key = {materie.id}
          name = {materie.nume}
          subtitle = {materie.numeprof}
          idElev={id}
          makeCallback = {makeCallback}
          medie = {medie}
          nrNote = {nrNote}
          nrAbsente = {absenteElev}
          nrAbsenteNemotivate = {nrAbsenteNemotivateMaterie}
          note = {materie.note}
          hasNoteAuthority = {hasRight && materie.idprofesor == idDirig}
          hasAbsenteAuthority = {hasRight}
          absente = {materie.absente}
          medieClasa = {medieClasa}
          nrNoteClasa = {nrNoteClasa}
          absenteClasa = {absenteClasa}
          noteNecesare = {Math.max(0, materie.nrore - materie.note.length)}
          medieScoala = {medieScoala}
          nrNoteScoala = {nrNoteScoala}
          absenteScoala = {absenteScoala}
          makeRefresh = {refreshInfo}
      />
      );
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "13px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
          <div style={{display: "flex", justifyContent: "end", gap: "5px"}}>
            <Button onClick={onPress} variant="outlined">Deschide tot</Button>
            <Button onClick={handleClick} variant="text">Sortează după<ExpandMoreIcon /></Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={sortAlfabetic}>Alfabetic</MenuItem>
              <MenuItem onClick={sortMedie}>Medie</MenuItem>
              <MenuItem onClick={sortAbsente}>Nr. absențe</MenuItem>
              <MenuItem onClick={sortNoteNecesare}>Nr. de note necesare</MenuItem>
            </Menu>
          </div>
        </div>
        <Container>
          <Card
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "10px",
              marginRight: "10px",
              maxWidth: "90vw",
              borderStyle: "solid",
              borderColor: "grey",
              padding: "5px",
              borderRadius: "25px"
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <h3 className="letterStyle">Număr absențe: {nrAbsente}</h3>
              </div>
            </div>
          </Card>
        </Container>
        <Container>
          <Card
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "10px",
              marginRight: "10px",
              maxWidth: "90vw",
              borderStyle: "solid",
              borderColor: "grey",
              padding: "5px",
              borderRadius: "25px"
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <h3 className="letterStyle">Număr absențe nemotivate: {nrAbsenteNemotivate}</h3>
              </div>
            </div>
          </Card>
        </Container>
        <Container>
          <Card
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "10px",
              marginRight: "10px",
              maxWidth: "90vw",
              borderStyle: "solid",
              borderColor: "grey",
              padding: "5px",
              borderRadius: "25px"
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <h3 className="letterStyle">Medie generală: {medieGenerala}</h3>
              </div>
            </div>
          </Card>
        </Container>
        {materii.map(makeMaterie)}
        <Container>
          <Card
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "10px",
              marginRight: "10px",
              maxWidth: "90vw",
              borderStyle: "solid",
              borderColor: "grey",
              padding: "5px",
              borderRadius: "25px"
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <h3 className="letterStyle">Notă purtare: {notaPurtare}</h3>
              </div>
            </div>
          </Card>
        </Container>
    </Container>
  );
}

export default InfotainmentElev;
