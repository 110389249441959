import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import MakeLogin from "../cmp/loginBox";

function LogInTab() {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <MakeLogin />
        <MakeFooter />
      </div>
    );
}

export default LogInTab;
   