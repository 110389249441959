import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import DialogModificaMaterie from "./popUpModificaMaterie";
import DialogModificaMaterieSecundar from "./popUpModificaOraSecundar";
import DialogModificaSala from "./popUpModificaSala";
import DialogModificaSalaOrar from "./popUpModificaSalaOrar";

function MakeCardOrar(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();

  async function handleClick() {
    try {
        await axios.post(
            "http://localhost:4000/api/stergeOrar", {
                token: cookies.get("_auth"),
                idOra: props.id
            }
        );

        props.refreshInfo();
      } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{display: "flex", flexDirection: "column", gap: "3px", justifyContent: "center"}}>
          <div style={{ display: "flex", alignItems: "center", gap: "6px", flexWrap: "wrap"}}>
              <h3 className="letterStyle">{props.timpInceputOra < 10 ? "0" : null}{props.timpInceputOra}:{props.timpInceputMinut < 10 ? "0" : null}{props.timpInceputMinut} - {props.timpSfarsitOra < 10 ? "0" : null}{props.timpSfarsitOra}:{props.timpSfarsitMinut < 10 ? "0" : null}{props.timpSfarsitMinut} {props.numeOra}</h3>
              <p className="letterStyle" style={{ marginTop: "7px" }}>
                  {props.numeProfesor} {props.numeSala != "NONE" ? props.numeSala : null}
              </p>  
          </div>
          {props.numeOraSecundar != "Liber" ? 
            <div style={{ display: "flex", alignItems: "center", gap: "6px", flexWrap: "wrap"}}>
                <h3 className="letterStyle">{props.numeOraSecundar}</h3>
                <p className="letterStyle" style={{ marginTop: "7px" }}>
                    {props.numeProfesorSecundar}
                </p>  
            </div>
            :
            null 
          }
        </div>
        {props.isAdmin ? 
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            gap: "3px",
            paddingRight: "17px"
          }}
        >
            <DialogModificaMaterie 
                idClasa={props.idClasa}
                idOrar={props.id}
                refreshInfo={props.refreshInfo}
            />
            <DialogModificaSalaOrar
                idOrar={props.id}
                refreshInfo={props.refreshInfo}
            />
            <DialogModificaMaterieSecundar
                idClasa={props.idClasa}
                idOrar={props.id}
                refreshInfo={props.refreshInfo}
                numeOra={props.numeOraSecundar}
            />
            <Button onClick={handleClick} color='success' variant='contained' style={{paddingLeft: "86px", paddingRight: "86px"}}>Șterge oră</Button>
        </div>
        :
        null
        }
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardOrar;
