import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";

export default function DialogReseteazaParola(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" onClick={handleClickOpen} style={{textTransform: 'none'}}>
        <p className='letterStyle'>Resetează parola</p>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle component="p">
          Dacă doriți să resetați parola, dați-ne un email la: catalogulscolii@gmail.com sau un mesaj la nr. de telefon: +40771600246 <span style={{fontWeight: "600"}}>de pe email-ul/numărul de telefon al contului</span>.
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}