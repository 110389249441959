import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import InfotainmentOrar from "../cmp/infotainmentOrar";
import InfotainmentSali from "../cmp/infotainmentSali";

function SaliTab(props) {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <InfotainmentSali />
        <MakeFooter />
      </div>
    );
}

export default SaliTab;
   