import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

function MakeCardProfesor(props) {
  const navigate = useNavigate();

  const manageOnClick = () => {
    if ( props.type == "clasa" ) {
      navigate("/clasa/" + props.id);
    } else {
      navigate("/elev/" + props.id);
    } 
  }

  const goToPrezenta = () => {
    navigate("/prezenta/" + props.id);
  }

  const manageOnClickIstoric = () => {
    navigate("/istoric/" + props.id);
  }

  const manageOnClickOrar = () => {
    navigate("/orarClasa/" + props.idClasa);
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center"}}>
          <h3 className="letterStyle">{props.name}</h3>
          {props.type == "clasa" ? 
            <h5 className="letterStyle" style={{paddingLeft: "8px", paddingTop: "5px"}}>{props.scoala}</h5>
            :
            null
          }
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "15px"
            }}
          >

            {props.type == "clasa" ? 
              null
              :
              <h6 className="letterStyle">Medie: {props.medie}</h6>
            }

            {props.type == "clasa" ? 
              null
              :
              <h6 className="letterStyle">Nr. absențe totale/nemotivate: <span style={{color: "green"}}>{props.nrAbsente}</span>/<span style={{color: "red"}}>{props.nrAbsenteNemotivate}</span></h6>
            }

          </div>
          <CardActions disableSpacing>
            <div style={{display: "flex", flexWrap: "wrap", flexDirection: "column", gap: "3px"}}>
            {props.showButtonPrezenta ? <Button onClick={goToPrezenta} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px"}}><p className="letterStyle">Fă prezenta</p></Button> : null}
            {props.type == "elev" ? <Button onClick = {manageOnClickIstoric} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px"}}><p className="letterStyle">Vezi istoric</p></Button> : null}
            {props.type == "elev" ? <Button onClick = {manageOnClickOrar} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px"}}><p className="letterStyle">Vezi orar</p></Button> : null}
            <Button onClick = {manageOnClick} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px"}}><p className="letterStyle">Vezi {props.type}</p></Button>
            </div>
          </CardActions>
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardProfesor;
