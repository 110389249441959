import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import InfotainmentDirector from "../cmp/infotainmentDirector";

function ClasaDirectorTab(props) {
    const cookies = new Cookies();
    const navigate = useNavigate();

    React.useEffect(() => {
        if ( !cookies.get("_auth") ) {
            navigate("/");
        }
      }, []);

    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <InfotainmentDirector />
        <MakeFooter />
      </div>
    );
}

export default ClasaDirectorTab;
   