import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import DialogSchimbaNumeClasa from "./popUpSchimbaNumeClasa";
import DialogSchimbaInfOra from "./popUpSchimbaInfOra";
import DialogSchimbaProfesorOra from "./popUpSchimbaProfesorOra";
import DialogSchimbaElev from "./popUpSchimbaElev";
import DialogSchimbaClasaElev from "./popUpSchimbaClasaElev";
import DialogSchimbaOreOptionale from "./popUpModificaOreOptionale";

function MakeCardElevAdmin(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{display: "flex", flexDirection: "column", gap: "2px"}}>
            <div style={{ display: "flex", alignItems: "center", gap: "6px", flexWrap: "wrap"}}>
            <h3 className="letterStyle">{props.name}</h3>
            <p className="letterStyle" style={{ marginTop: "7px" }}>
                Nr. matricol: {props.nrmatricol}
            </p>
            </div>
            <h5 className="letterStyle" style={{marginBottom: "4px"}}>Adresă: {props.adresa}</h5>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            gap: "4px",
            paddingRight: "17px"
          }}
        >
            <DialogSchimbaElev
                idElev={props.id}
                adresa={props.adresa}
                nrmatricol={props.nrmatricol}
                name={props.name}
                refreshInfo={props.refreshInfo}
                mail={props.mail}
                mailelev={props.mailelev}
            />
            <DialogSchimbaOreOptionale
                idElev={props.id}
            />
            <DialogSchimbaClasaElev
                idElev={props.id}
                refreshInfo={props.refreshInfo}
            />
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardElevAdmin;
