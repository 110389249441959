import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import { Container } from "@mui/material";

function ContactTab() {
    const [showSpace, setShowSpacce] = React.useState(window.innerWidth > 700);

    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <Container>
        {showSpace ? 
            <div style={{marginTop: "20vh"}}></div> : null}
          <div style={{display: "flex", flexDirection: "column", marginTop: "2vh", gap: "15px"}}>
          <h2 className="letterStyle" style={{marginBottom: "10px"}}>Contactați-ne!</h2>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Doriți să aflați mai multe informații despre site sau să raportați o problemă? Vă stăm la dispoziție!</p>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Ne puteți contacta oricând pe email la: <span style={{fontWeight: "600"}}>catalogulscolii@gmail.com</span> sau direct pe telefon sunând la numărul: <span style={{fontWeight: "600"}}>+40771600246</span>, iar noi vom face tot posibilul pentru a vă îmbunătăți experiența cu programul nostru!</p>
          </div>
        </Container>
        <MakeFooter />
      </div>
    );
}

export default ContactTab;
   