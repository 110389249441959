import React from "react";
import Button from '@mui/material/Button';
import "../cssFiles/basicStyles.css"

function MakeCardSalaPopUp(props) {
    function makePress() {
        props.makeUpdate(props.id);
    }

    return (
        <Button variant="outlined" onClick={makePress}><p className="letterStyle">{props.nume}</p></Button>
    );
}

export default MakeCardSalaPopUp;