import React from "react";
import Container2 from 'react-bootstrap/Container';
import { Container } from "@mui/material";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import MakeButton from "./headerBtn";
import "../cssFiles/styles.css"
import { GripVertical } from "react-bootstrap-icons";
import Cookies from "universal-cookie";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import logo from "../images/logoWebsiteWhite.png";

function MakeHeader() {
    const navbarColor = {backgroundColor: '#002b7f', paddingLeft: "0", paddingRight: "0"};
    const [isLoggedIn, changeStateLogIn] = React.useState(false);
    const cookies = React.useMemo(() => {return new Cookies()}, []);
    const navigate = useNavigate();

    React.useEffect(() => {
      if ( cookies.get("_auth") ) {
        changeStateLogIn(true);
      }
    }, [cookies, navigate]);

    const logOutClick = async () => {
      await cookies.remove("_auth");
      if ( cookies.get("_auth") ) {
        navigate(0);
      }
      changeStateLogIn(false);
      navigate("/");
    } 
    
    return (
        <div>
        <div className="blueFlag">
        <Container>
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" style={navbarColor}>
            <Navbar.Brand href="/"><div style={{display: "flex", alignItems: "center", paddingTop: "10px"}}><img src={logo} alt="Logo" style={{width: "60px"}}/><h4 className="titleInHeader" style={{paddingBottom: "10px", fontSize: "20pt"}}>Catalog Online</h4></div></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"><GripVertical color="white" size={25}/></Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content-end flex-grow-1 pe-2">
                {!isLoggedIn ? 
                <MakeButton loc="/" text="Despre noi"/>
                :
                <MakeButton loc="/home" text="Acasa" />
                }
                <MakeButton loc="/servicii" text="Servicii"/>
                <MakeButton loc="/contact" text="Contactati-ne!"/>
                {isLoggedIn ? 
                  <div>
                    <MakeButton loc="/setari" text="Setari"/>
                    <Button variant="text" color="inherit" onClick={logOutClick}><p className="textInHeader">Logout</p></Button>
                  </div>
                  :
                  <MakeButton loc="/login" text="Login"/>
                }
              </Nav>
            </Navbar.Collapse>
        </Navbar>
        </Container>
        </div>
        <div className="makeFlag">
            <div className="blueFlag"></div>
            <div className="yellowFlag"></div>
            <div className="redFlag"></div>
        </div>
        </div>
    );
}

export default MakeHeader;

/*
                <Button variant="text" color="inherit"><p className="textInHeader">Servicii</p></Button>
                <Button variant="text" color="inherit"><p className="textInHeader">Contactati-ne!</p></Button>
                <Button variant="text" color="inherit"><p className="textInHeader">Login</p></Button>
                */