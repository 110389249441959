import React from "react";
import { Container } from "@mui/material";
import "../cssFiles/styles.css";
import "../cssFiles/loginStyle.css";
import "../cssFiles/basicStyles.css";
import { Box, Button, TextField, createTheme } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

function Setari() {
  const [err, changeErr] = React.useState("");
  const [success, changeSuccess] = React.useState("");
  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  
  React.useEffect(() => {
    if ( !cookies.get("_auth") ) {
      navigate("/");
    }
  }, [cookies, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if ( !cookies.get("_auth") ) {
        navigate("/");
    }

    try {
      await axios.post(
        "http://localhost:4000/api/changeSettings", {
          email: event.target.email.value,
          tel: event.target.tel.value,
          newPassword: event.target.newPassword.value,
          password: event.target.password.value,
          token: cookies.get("_auth") 
        }
      );

      changeErr("");
      changeSuccess("Modificări realizate cu success!");
    } catch (err) {
      changeSuccess("");
      changeErr(err.response.data);
    }
  };

  return (
    <Container className="loginBoxInfo" style={{display: "flex", justifyContent: "center"}}>
      <Box className="loginBoxInfo styledLoginBox" maxWidth='375px'>
        <h3 className="writingStyleTitle"> Setări </h3>
        {success.length ? <div style={{display: "flex", justifyContent: "center", backgroundColor: "#245710", borderStyle: "solid", borderRadius: "8px", borderColor: "#336D1D", padding: "3px 3px 3px 3px"}}><p className="letterStyle" style={{color: "white"}}>{success}</p></div> : null}
        {err.length ? <div style={{display: "flex", justifyContent: "center", backgroundColor: "#FF2635", borderStyle: "solid", borderRadius: "8px", borderColor: "#FF404D", padding: "3px 2px 3px 2px"}}><p className="letterStyle">{err}</p></div> : null}
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            fullWidth
            label="Număr nou de telefon"
            name="tel"
            autoFocus
          />
          <TextField
            margin="normal"
            fullWidth
            name="email"
            label="Email nou"
          />
          <TextField
            margin="normal"
            fullWidth
            name="newPassword"
            label="Resetează parola"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Parola curentă"
            type="password"
            autoComplete="current-password"
          />
          <Box className="boxWithText" style={{justifyContent: "end"}}>
            <Button type="submit" className="buttonToSubmit" variant="contained">
              <p className="writignStyle centerSelf"> Ok </p>
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Setari;
