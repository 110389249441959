import React from "react";
import ReactDOM from "react-dom";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import Setari from "../cmp/setari";

function SetariTab() {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <Setari />
        <MakeFooter />
      </div>
    );
}

export default SetariTab;
   