import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import "../cssFiles/basicStyles.css"
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import MakeCardProfesor from "./boxProfesor";
import MakeCardDirector from "./boxDirector";
import MakeCardAdmin from "./boxAdmin";
import { Button } from "@mui/material";
import MakeCardGoToProfesor from "./boxGoToProfesori";
import MakeCardOrar from "./boxOrar";
import MakeCardAddOra from "./boxAddOra";
import DialogModificaInceputOre from "./popUpModificaInceputOre";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Card from "@mui/material/Card";
import { degrees, PDFDocument, rgb, StandardFonts, TextAlignment } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit"

function InfotainmentOrar(props) {
  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const {id} = useParams();
  const [isAdmin, changeIsAdmin] = React.useState(false);
  const zile = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri'];
  const [ore0, changeOre0] = React.useState([]);
  const [ore1, changeOre1] = React.useState([]);
  const [ore2, changeOre2] = React.useState([]);
  const [ore3, changeOre3] = React.useState([]);
  const [ore4, changeOre4] = React.useState([]);
  const [oreTotal, setOreTotal] = React.useState([]);
  const [startOreOra, setStartOreOra] = React.useState(0);
  const [startOreMinut, setStartOreMinut] = React.useState(0);
  const [numeClasa, setNumeClasa] = React.useState("");

  const sortVar = (x) => {
    var aux = x;
    
    aux.sort((a, b) => {
      let xZi = a.zi;
      let yZi = b.zi;

      if ( xZi > yZi ) {
        return 1;
      }
      if ( xZi < yZi ) {
        return -1;
      }

      let xIndexOra = a.indexora;
      let yIndexOra = b.indexora;

      if ( xIndexOra > yIndexOra ) {
        return 1;
      }
      if ( xIndexOra < yIndexOra ) {
        return -1;
      }

      return 0;
    });

    var ans0 = [];
    var ans1 = [];
    var ans2 = [];
    var ans3 = [];
    var ans4 = [];

    for ( var i = 0; i < aux.length; i++ ) {
        if ( aux[i].zi == 0 ) {
          ans0.push(aux[i]);
        } else if ( aux[i].zi == 1 ) {
          ans1.push(aux[i]);
        } else if ( aux[i].zi == 2 ) {
          ans2.push(aux[i]);
        } else if ( aux[i].zi == 3 ) {
          ans3.push(aux[i]);
        } else {
          ans4.push(aux[i]);
        }
    }

    changeOre0(ans0);
    changeOre1(ans1);
    changeOre2(ans2);
    changeOre3(ans3);
    changeOre4(ans4);
  }

  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
    if ( !cookies.get("_auth") ) {
      navigate("/");
    }

    async function fetchDataOrar() {
        try {
            const orar = await axios.post(
            "http://localhost:4000/api/getOrarClasa", {
                token: cookies.get("_auth"),
                idClasa: id
            }
            );

            setNumeClasa(orar.data.numeClasa);
            setOreTotal(orar.data.orar);
            setStartOreMinut(orar.data.inceputOreMinut);
            setStartOreOra(orar.data.inceputOreOra);
            sortVar(orar.data.orar);
            changeIsAdmin(orar.data.isAdmin);
        } catch (err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }
    
    if ( oneTime ) {
      fetchDataOrar();
      setOneTime(false);
    }
  }, [cookies, navigate]);

  async function refreshInfo() {
    try {
        const orar = await axios.post(
          "http://localhost:4000/api/getOrarClasa", {
            token: cookies.get("_auth"),
            idClasa: id
          }
        );

        setNumeClasa(orar.data.numeClasa);
        setOreTotal(orar.data.orar);
        setStartOreMinut(orar.data.inceputOreMinut);
        setStartOreOra(orar.data.inceputOreOra);
        sortVar(orar.data.orar);
        changeIsAdmin(orar.data.isAdmin);
      } catch (err) {
        cookies.remove("_auth");
        navigate("/");
      }
  }

  function makeOrar(ora) {
    return (
        <MakeCardOrar
          key = {ora.id}
          id = {ora.id}
          idClasa = {id}
          timpInceputOra = {ora.timpinceputora}
          timpInceputMinut = {ora.timpinceputminut}
          timpSfarsitOra = {ora.timpsfarsitora}
          timpSfarsitMinut = {ora.timpsfarsitminut}
          isAdmin = {isAdmin}
          numeOra = {ora.numeora}
          refreshInfo = {refreshInfo}
          numeProfesor = {ora.numeprof}
          numeSala = {ora.numesala}
          numeOraSecundar = {ora.numeorasecundar}
          numeProfesorSecundar = {ora.numeprofsecundar}
        />
    );
  }

  const goBack = () => {
    navigate(-1);
  }

  const downloadOrar = async () => {
    try {
      const url = 'http://localhost:4000/orarSmall.pdf';
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
      
      const ubuntuFontBytes = await fetch(
        'http://localhost:4000/niceFont.ttf',
      ).then((res) => res.arrayBuffer());

      const finalPdf = await PDFDocument.load(existingPdfBytes);
      finalPdf.registerFontkit(fontkit);
      const goodFont = await finalPdf.embedFont(ubuntuFontBytes);

      var maxLine = 0;

      const page = finalPdf.getPage(0);

      for ( var i = 0; i < oreTotal.length; i++ ) {
        maxLine = Math.max(maxLine, oreTotal[i].indexora + 1);
      }

      ///y = 595
      ///x = 840

      ///x = 20 (start tabel)
      {
        const textWidth = goodFont.widthOfTextAtSize(numeClasa, 33);
  
        page.drawText(numeClasa, {
          x: Math.floor((840 - textWidth) / 2),
          y: 550,
          font: goodFont,
          size: 33,
          color: rgb(0, 0, 0),
          lineHeight: 24,
          opacity: 0.75,
        });
      }

      if ( maxLine ) {
        var y = [532, 481, 388, 295, 207, 120, 30];
        var x = [];
        var startX = 104;
        var endX = 820;
        var pas = Math.floor((endX - startX + 1) / maxLine);
        var hasTime = [];

        for ( var i = 0; i <= 6; i++ ) {
          page.drawLine({
            start: { x: 20, y: y[i]},
            end: { x: startX + pas * maxLine, y: y[i]},
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 0.75
          });
        }

        x.push(startX);
        for ( var i = 0; i < maxLine; i++ ) {
          hasTime.push(false);
          x.push(startX + pas * (i + 1));
          page.drawLine({
            start: { x: startX + pas * (i + 1), y: y[0]},
            end: { x: startX + pas * (i + 1), y: y[6]},
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 0.75
          });

          const textWidth = goodFont.widthOfTextAtSize((i + 1).toString(), 20);
          page.drawText((i + 1).toString(), {
            x: startX + pas * i + Math.floor((pas - textWidth) / 2),
            y: y[0] - 20,
            font: goodFont,
            size: 20,
            color: rgb(0, 0, 0),
            lineHeight: 24,
            opacity: 0.75,
          });
        }

        for ( var i = 0; i < oreTotal.length; i++ ) {
          if ( !hasTime[oreTotal[i].indexora] ) {
            var aux = [oreTotal[i].timpinceputora.toString(), oreTotal[i].timpinceputminut.toString(), oreTotal[i].timpsfarsitora.toString(), oreTotal[i].timpsfarsitminut.toString()];
            for ( var i2 = 0; i2 < 4; i2++ ) {
              if ( aux[i2].length == 1 ) {
                aux[i2] = "0" + aux[i2];
              }
            }

            const textWidth = goodFont.widthOfTextAtSize(aux[0] + ":" + aux[1] + " - " + aux[2] + ":" + aux[3], 8);
            page.drawText(aux[0] + ":" + aux[1] + " - " + aux[2] + ":" + aux[3], {
              x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2),
              y: y[0] - 45,
              font: goodFont,
              size: 8,
              color: rgb(0, 0, 0),
              lineHeight: 24,
              opacity: 0.75,
            });   
            hasTime[oreTotal[i].indexora] = true;         
          }

          if ( oreTotal[i].numeora != "Liber" ) {
            if ( oreTotal[i].numesala != "NONE" ) {
              var textSize = 9;
              var textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numesala, textSize);

              while ( textWidth >= pas - 10 ) {
                textSize--;
                textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numesala, textSize);
              }
              page.drawText(oreTotal[i].numesala, {
                x: startX + pas * (oreTotal[i].indexora + 1) - textWidth - 5,
                y: y[oreTotal[i].zi + 1] - 12,
                font: goodFont,
                size: textSize,
                color: rgb(0, 0, 0),
                lineHeight: 10,
                opacity: 0.75,
              });
            }
            if ( oreTotal[i].numeorasecundar == "Liber" ) {
              textSize = 17;
              textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeora, textSize);

              while ( textWidth >= pas - 3 ) {
                textSize--;
                textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeora, textSize);
              }

              page.drawText(oreTotal[i].numeora, {
                x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2),
                y: y[oreTotal[i].zi + 1] - 30,
                font: goodFont,
                size: textSize,
                color: rgb(0, 0, 0),
                lineHeight: 24,
                opacity: 0.75,
              });

              textSize = 10;
              textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeprof, textSize);

              while ( textWidth >= pas - 5 ) {
                textSize--;
                textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeprof, textSize);
              }

              page.drawText(oreTotal[i].numeprof, {
                x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2) + 2,
                y: y[oreTotal[i].zi + 1] - 84,
                font: goodFont,
                size: textSize,
                color: rgb(0, 0, 0),
                lineHeight: 24,
                opacity: 0.75,
              });
            } else {
              page.drawLine({
                start: { x: startX + pas * oreTotal[i].indexora, y: Math.floor((y[oreTotal[i].zi + 1] + y[oreTotal[i].zi + 2]) / 2)},
                end: { x: startX + pas * (oreTotal[i].indexora + 1), y: Math.floor((y[oreTotal[i].zi + 1] + y[oreTotal[i].zi + 2]) / 2)},
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.75
              });
              textSize = 17;
              textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeora, textSize);

              while ( textWidth >= pas - 3 ) {
                textSize--;
                textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeora, textSize);
              }

              page.drawText(oreTotal[i].numeora, {
                x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2),
                y: y[oreTotal[i].zi + 1] - 25,
                font: goodFont,
                size: textSize,
                color: rgb(0, 0, 0),
                lineHeight: 24,
                opacity: 0.75,
              });

              textSize = 10;
              textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeprof, textSize);

              while ( textWidth >= pas - 5 ) {
                textSize--;
                textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeprof, textSize);
              }

              page.drawText(oreTotal[i].numeprof, {
                x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2) + 2,
                y: y[oreTotal[i].zi + 1] - 42,
                font: goodFont,
                size: textSize,
                color: rgb(0, 0, 0),
                lineHeight: 24,
                opacity: 0.75,
              });


              textSize = 17;
              textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeorasecundar, textSize);
              const goodY = Math.floor((y[oreTotal[i].zi + 1] + y[oreTotal[i].zi + 2]) / 2);

              while ( textWidth >= pas - 3 ) {
                textSize--;
                textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeorasecundar, textSize);
              }

              page.drawText(oreTotal[i].numeorasecundar, {
                x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2),
                y: goodY - 25,
                font: goodFont,
                size: textSize,
                color: rgb(0, 0, 0),
                lineHeight: 24,
                opacity: 0.75,
              });

              textSize = 10;
              textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeprofsecundar, textSize);

              while ( textWidth >= pas - 5 ) {
                textSize--;
                textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeprofsecundar, textSize);
              }

              page.drawText(oreTotal[i].numeprofsecundar, {
                x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2) + 2,
                y: goodY - 40,
                font: goodFont,
                size: textSize,
                color: rgb(0, 0, 0),
                lineHeight: 24,
                opacity: 0.75,
              });
            }
          }
        }
      }

      const pdfBytes = await finalPdf.save();

      const blob = new Blob([pdfBytes]);
      const fileUrl = window.URL.createObjectURL(blob);
  
      let alink = document.createElement("a");
      alink.href = fileUrl;
      alink.download = "Orar" + "_" + numeClasa + ".pdf";
      alink.click();
    } catch(err) {
      cookies.remove("_auth");
      navigate("/");
    }
  }


  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "0px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
          <Button onClick={downloadOrar} variant="contained">Descarcă orar</Button>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          {isAdmin ? 
            <Card
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "10px",
              marginRight: "10px",
              maxWidth: "90vw",
              borderStyle: "solid",
              borderColor: "grey",
              padding: "5px",
              borderRadius: "25px"
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "6px"}}>
                  <h3 className="letterStyle">Început ore: {startOreOra < 10 ? "0" : null}{startOreOra}:{startOreMinut < 10 ? "0" : null}{startOreMinut}</h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "3px",
                  paddingRight: "17px"
                }}
              >
                  <DialogModificaInceputOre
                    startOreMinut = {startOreMinut}
                    startOreOra = {startOreOra}
                    idClasa = {id}
                    refreshInfo = {refreshInfo}
                  />
              </div>
            </div>
          </Card>
          :
          null
          }
          <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {zile[0]} </h3> 
          {ore0.map(makeOrar)}
          {isAdmin?
            <div style={{display: "flex", justifyContent: "end"}}>
              <MakeCardAddOra
                zi = {0}
                idClasa = {id}
                refreshInfo = {refreshInfo}
              />
            </div>
            :
            null
          }
          <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {zile[1]} </h3> 
          {ore1.map(makeOrar)}
          {isAdmin?
            <div style={{display: "flex", justifyContent: "end"}}>
              <MakeCardAddOra
                zi = {1}
                idClasa = {id}
                refreshInfo = {refreshInfo}
              />
            </div>
            :
            null
          }
          <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {zile[2]} </h3> 
          {ore2.map(makeOrar)}
          {isAdmin?
            <div style={{display: "flex", justifyContent: "end"}}>
              <MakeCardAddOra
                zi = {2}
                idClasa = {id}
                refreshInfo = {refreshInfo}
              />
            </div>
            :
            null
          }
          <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {zile[3]} </h3> 
          {ore3.map(makeOrar)}
          {isAdmin?
            <div style={{display: "flex", justifyContent: "end"}}>
              <MakeCardAddOra
                zi = {3}
                idClasa = {id}
                refreshInfo = {refreshInfo}
              />
            </div>
            :
            null
          }
          <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {zile[4]} </h3> 
          {ore4.map(makeOrar)}
          {isAdmin?
            <div style={{display: "flex", justifyContent: "end"}}>
              <MakeCardAddOra
                zi = {4}
                idClasa = {id}
                refreshInfo = {refreshInfo}
              />
            </div>
            :
            null
          }
        </div>
    </Container>
  );
}

export default InfotainmentOrar;