import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import "../cssFiles/basicStyles.css"
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import MakeCardProfesor from "./boxProfesor";
import MakeCardDirector from "./boxDirector";
import MakeCardAdmin from "./boxAdmin";
import { Button } from "@mui/material";
import MakeCardGoToProfesor from "./boxGoToProfesori";
import MakeCardLungimeOre from "./boxLungimeOre";
import MakeCardGoToSali from "./boxGoToSali";

function InfotainmentMain(props) {
  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const [elevi, changeElevi] = React.useState([]);
  const [clasaMea, changeClasaMea] = React.useState([]);
  const [clase, changeClase] = React.useState([]);
  const [claseDirector, changeClaseDirector] = React.useState([]);
  const [claseAdmin, changeClaseAdmin] = React.useState([]);
  const [lungimePauza, setLungimePauza] = React.useState(0);
  const [lungimeOra, setLungimeOra] = React.useState(0);

  const sortAlfabeticVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.numeclasa.toLowerCase();
      let y = b.numeclasa.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    changeClase(aux);
  }

  const sortAlfabeticDirectorVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.numeclasa.toLowerCase();
      let y = b.numeclasa.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      
      let numeScoalaX = a.scoala.toLowerCase();
      let numeScoalaY = b.scoala.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }

      let materieX = a.nume.toLowerCase();
      let materieY = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    changeClaseDirector(aux);
  }

  const sortAlfabeticAdminVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.numeclasa.toLowerCase();
      let y = b.numeclasa.toLowerCase();

      if ( x == "elevi fara clasa atribuita" || y == "elevi fara clasa atribuita" ) {
        if ( x == "elevi fara clasa atribuita" ) {
          return -1;
        }
        return 1;
      }

      if ( x == "null" || y == "null" ) {
        if ( x == y ) {
          return 0;
        }
        if ( x == "null" ) {
          return -1;
        }
        return 1;
      }

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    changeClaseAdmin(aux);
  }

  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
    if ( !cookies.get("_auth") ) {
      navigate("/");
    }

      async function fetchDataElevi() {
        try {
          const elev = await axios.post(
            "http://localhost:4000/api/getElevi", {
              token: cookies.get("_auth") 
            }
          );

          changeElevi(elev.data.elevi);
        } catch (err) {
          cookies.remove("_auth");
          navigate("/");
        }
      }

      async function fetchDataClasaMea() {
        try {
          const clasa = await axios.post(
            "http://localhost:4000/api/getClasaMea", {
              token: cookies.get("_auth") 
            }
          );


          changeClasaMea(clasa.data.clasaMea);
        } catch (err) {
          cookies.remove("_auth");
          navigate("/");
        }
      }

      async function fetchDataClase() {
        try {
          const clasa = await axios.post(
            "http://localhost:4000/api/getClase", {
              token: cookies.get("_auth") 
            }
          );


          sortAlfabeticVar(clasa.data.clase);
        } catch (err) {
          cookies.remove("_auth");
          navigate("/");
        }
      }

      async function fetchDataClaseDirector() {
        try {
          const clasa = await axios.post(
            "http://localhost:4000/api/getClaseDirector", {
              token: cookies.get("_auth") 
            }
          );

          sortAlfabeticDirectorVar(clasa.data.clase);
        } catch (err) {
          cookies.remove("_auth");
          navigate("/");
        }
      }

      async function fetchDataClaseAdmin() {
        try {
          const clasa = await axios.post(
            "http://localhost:4000/api/getClaseAdmin", {
              token: cookies.get("_auth") 
            }
          );

          setLungimeOra(clasa.data.lungimeOra);
          setLungimePauza(clasa.data.lungimePauza);
          sortAlfabeticAdminVar(clasa.data.clase);
        } catch (err) {
          cookies.remove("_auth");
          navigate("/");
        }
      }
    
      if ( oneTime ) {
      fetchDataElevi();
      fetchDataClasaMea();
      fetchDataClase();
      fetchDataClaseDirector();
      fetchDataClaseAdmin();
      setOneTime(false);
    }
  }, [cookies, navigate]);

  async function refreshAdmin() {
    try {
      const clasa = await axios.post(
        "http://localhost:4000/api/getClaseAdmin", {
          token: cookies.get("_auth") 
        }
      );

      setLungimeOra(clasa.data.lungimeOra);
      setLungimePauza(clasa.data.lungimePauza);
      sortAlfabeticAdminVar(clasa.data.clase);
    } catch (err) {
      cookies.remove("_auth");
      navigate("/");
    }
  }

  function makeElevi(elev) {
    var medie = Math.round(100 * (elev.sumamaterii + elev.notapurtare) / elev.nrmaterii) / 100;
    var nrAbsente = "-";

    if ( elev.nrabsente ) {
      nrAbsente = elev.nrabsente;
    }

    var nrAbsenteNemotivate = "-";

    if ( elev.nrAbsenteNemotivate ) {
      nrAbsenteNemotivate = elev.nrAbsenteNemotivate;
    }

    return (
      <MakeCardProfesor
        key = {elev.id}
        id = {elev.id}
        idClasa = {elev.idclasa}
        type = "elev"
        medie = {medie}
        nrAbsente = {nrAbsente}
        nrAbsenteNemotivate = {nrAbsenteNemotivate}
        showButtonPrezenta = {false}
        name = {elev.nume}
      />
    )
  }

  function makeClasa(clasa) {
    return (
      <MakeCardDirector
        key = {clasa.id}
        id = {clasa.id}
        name = {clasa.numeclasa} 
      />
    );
  }

  function makeOra(clasa) {
    return (
      <MakeCardProfesor
        key = {clasa.id}
        id = {clasa.id}
        type = "clasa"
        name = {clasa.numeclasa + " " + clasa.nume}
        scoala = {clasa.scoala} 
        showButtonPrezenta = {true} />
    );
  }

  function makeClasaDirector(clasa) {
    if ( clasa.numeclasa == "Elevi fara clasa atribuita" ) {
      return (null);
    }
    return (
      <MakeCardDirector
        key = {clasa.id}
        id = {clasa.id}
        name = {clasa.numeclasa} 
        numeDirig = {clasa.nume}
      />
    );
  }

  function makeClasaAdmin(clasa) {
    return (
      <MakeCardAdmin
        key = {clasa.id}
        id = {clasa.id}
        name = {clasa.numeclasa}
        refreshInfo = {refreshAdmin}
        numedirig={clasa.numedirig}
      />
    )
  }

  async function adaugaClasa() {
    try {
      await axios.post(
          "http://localhost:4000/api/adaugaClasa", {
              token: cookies.get("_auth"),
          }
      );

      refreshAdmin();
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  function goToProgram() {
    navigate("/program");
  }

  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          {elevi.length ? 
              <div style={{display: "flex", flexDirection: "column"}} >
                <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid"}}>Elevi</h3>
                {elevi.map(makeElevi)}
               </div>
              :
              null
          }
          {clasaMea.length ? 
              <div style={{display: "flex", flexDirection: "column"}} >
                <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid"}}>Clasa mea</h3>
                {clasaMea.map(makeClasa)}
               </div>
              :
              null
          }
          {clase.length ? 
              <div style={{display: "flex", flexDirection: "column"}} >
                <div style={{display: "flex", justifyContent: "space-between", borderBottomColor: "grey", borderBottomStyle: "solid", paddingBottom: "5px"}}>
                    <h3 className="letterStyle">Ore</h3>
                    <Button onClick={goToProgram} variant="contained">Vezi program</Button>
                </div>
                {clase.map(makeOra)}
               </div>
              :
              null
          }
          {claseDirector.length ? 
              <div style={{display: "flex", flexDirection: "column"}} >
                <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid"}}>Clase</h3>
                {claseDirector.map(makeClasaDirector)}
               </div>
              :
              null
          }
          {claseAdmin.length ? 
              <div style={{display: "flex", flexDirection: "column"}} >
                <div style={{display: "flex", justifyContent: "space-between", borderBottomColor: "grey", borderBottomStyle: "solid", paddingBottom: "5px"}}>
                  <h3 className="letterStyle">Clase administrator</h3>
                  <Button onClick={adaugaClasa} variant="contained">Adaugă clasă</Button>
                </div>
                <MakeCardLungimeOre
                  refreshInfo={refreshAdmin}
                  lungimeOra={lungimeOra}
                  lungimePauza={lungimePauza}
                />
                <MakeCardGoToProfesor />
                <MakeCardGoToSali />
                {claseAdmin.map(makeClasaAdmin)}
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "end", paddingTop: "5px", borderTopStyle: "solid", borderTopColor: "grey", marginTop: "13px"}}>
                  <Button onClick={adaugaClasa} variant="contained">Adaugă clasă</Button>
                </div>
               </div>
              :
              null
          }
        </div>
    </Container>
  );
}

export default InfotainmentMain;

/*
    */