import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import InfotainmentOreAdmin from "../cmp/infotainmentOreAdmin";

function OreAdminTab() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    React.useEffect(() => {
      if ( !cookies.get("_auth") ) {
        navigate("/");
      }
    }, []);

    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <InfotainmentOreAdmin />
        <MakeFooter />
      </div>
    );
}

export default OreAdminTab;
   