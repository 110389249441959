import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function DialogSchimbaClasaElev(props) {
  const [open, setOpen] = React.useState(false);
  const [openNumeInexistent, setOpenNumeInexistent] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseNumeInexistent();
  };

  function handleOpenNumeInexistent() {
    setOpenNumeInexistent(true);
  }

  function handleCloseNumeInexistent() {
    setOpenNumeInexistent(false);
  }

  const handleSubmitNume = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post("http://localhost:4000/api/gasesteClasa", {
            token: cookies.get("_auth"),
            numeClasa: event.target.name.value
        });
        if ( response.data.id >= 0 ) {
            await makeUpdate(response.data.id);
            props.refreshInfo();
            handleClose();
        } else {
            handleOpenNumeInexistent();
        }
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }; 

  async function makeUpdate(idClasa) {
    try {
        await axios.post("http://localhost:4000/api/modificaClasaElev", {
            token: cookies.get("_auth"),
            idElev: props.idElev,
            idClasa: idClasa
        });
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} color='success' variant='contained' style={{paddingLeft: "15px", paddingRight: "15px"}}>Modifică clasă elev</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Introdu nume clasa</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmitNume}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Nume clasa noua"
                    autoFocus
                />
                <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                    <Button onClick={handleClose} variant='text'>Închide</Button>
                    <Button type="submit" variant="contained"> Ok </Button>
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openNumeInexistent}
        onClose={handleCloseNumeInexistent}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Nume clasă inexistent</DialogTitle>
        <DialogContent dividers={true}>
            <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                <Button onClick={handleCloseNumeInexistent} variant='text'>Ok</Button>
            </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}