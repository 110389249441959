import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import InfotainmentMain from "../cmp/infotainmentMain";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

function HomeTab() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    React.useEffect(() => {
      if ( !cookies.get("_auth") ) {
        navigate("/");
      }
    }, []);

    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <InfotainmentMain />
        <MakeFooter />
      </div>
    );
}

export default HomeTab;
   