import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, DialogActions, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import MakeCardSalaPopUp from './boxSalaPopUp';
import MakeCardMateriePopUp from './boxMateriePopUp';

export default function DialogSchimbaOreOptionale(props) {
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [materii, setMaterii] = React.useState([]);
  var states = [];
  var hasChanged = [];

  async function handleClickOpen() {
    setOpen(true);
    async function testIntegrity() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getMateriiAdmin", {
                    token: cookies.get("_auth"),
                    idElev: props.idElev
                }
            );

            sortAlfabetic(response.data.ore);
          } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    await testIntegrity();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sortAlfabetic = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    for ( var i = 0; i < aux.length; i++ ) {
        aux[i].pos = i;
    }

    setMaterii(aux);
  }

  async function onClickUpdate() {
    try {
        for ( var i = 0; i < materii.length; i++ ) {
            if ( hasChanged[i] ) {
                console.log(states[i]);
                await axios.post(
                    "http://localhost:4000/api/modificaMaterieElev", {
                        token: cookies.get("_auth"),
                        idOra: materii[i].id,
                        idElev: props.idElev,
                        state: states[i]
                    }
                );
            }
        }

        handleClose();
      } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  async function updateInfo(pos) {
    states[pos] = states[pos] ^ 1;
    hasChanged[pos] = hasChanged[pos] ^ 1;
    console.log(states[pos], hasChanged[pos]);
  }

  function makeCardMaterie(materie) {
    states[materie.pos] = materie.faceora;
    hasChanged[materie.pos] = false;
    return (
        <MakeCardMateriePopUp
            key = {materie.id}
            id = {materie.pos}
            nume = {materie.nume}
            faceora = {materie.faceora}
            makeUpdate = {updateInfo}
        />
    )
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} color='success' variant='contained' style={{paddingLeft: "78px", paddingRight: "78px"}}>Modifică ore opționale</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Modifică ore opționale</DialogTitle>
        <DialogContent dividers={true}>
        <div style={{display: "flex", flexDirection: "column", gap: "3px"}}>
          {materii.map(makeCardMaterie)}
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Închide</Button>
          <Button onClick={onClickUpdate}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}