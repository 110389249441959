import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function DialogModificaLungimeOre(props) {
  const [open, setOpen] = React.useState(false);
  const currDate = new Date();
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        await axios.post("http://localhost:4000/api/modificaLungimeOra", {
            token: cookies.get("_auth"),
            oraLungime: event.target.ora.value,
            pauzaLungime: event.target.pauza.value
        });

        props.refreshInfo();
        handleClose();
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }; 

  return (
    <React.Fragment>
      <Button variant='contained' color='success' onClick={handleClickOpen}>Modifică lungime ore</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Modifică lungime ore</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmit}>
              <div style={{display: "flex", gap: "3px"}}>
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="ora"
                      label="Lungime ore"
                      type="number"
                      defaultValue={props.lungimeOra}
                      autoFocus
                  />
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Lungime pauza"
                      name="pauza"
                      type="number"
                      defaultValue={props.lungimePauza}
                  />
              </div>
              <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                  <Button onClick={handleClose} variant='text'>Închide</Button>
                  <Button type="submit" variant="contained"> Ok </Button>
              </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}