import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function DialogSchimbaProfesorOra(props) {
  const [open, setOpen] = React.useState(false);
  const [openNumeInexistent, setOpenNumeInexistent] = React.useState(false);
  const [introduEmail, setIntroduEmail] = React.useState(false);
  const [preaMulteNume, setPreaMulteNume] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseIntroduEmail();
    handleCloseNumeInexistent();
    setPreaMulteNume(false);
  };

  function handleOpenNumeInexistent() {
    setOpenNumeInexistent(true);
  }

  function handleCloseNumeInexistent() {
    setOpenNumeInexistent(false);
  }

  function handleOpenIntroduEmail() {
    setIntroduEmail(true);
  }

  function handleCloseIntroduEmail() {
    setPreaMulteNume(false);
    setIntroduEmail(false);
  }

  const handleSubmitNume = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post("http://localhost:4000/api/gasesteProf", {
            token: cookies.get("_auth"),
            numeProf: event.target.name.value
        });
        if ( response.data.id >= 0 ) {
            await makeUpdate(response.data.id);
            props.refreshInfo();
            handleClose();
        } else if ( response.data.id == -1 ) {
            handleOpenNumeInexistent();
        } else {
            setPreaMulteNume(true);
            handleOpenIntroduEmail();
        }
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }; 

  const handleSubmitEmail = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post("http://localhost:4000/api/gasesteProfEmail", {
            token: cookies.get("_auth"),
            mailProf: event.target.name.value
        });
        if ( response.data.id >= 0 ) {
            await makeUpdate(response.data.id);
            props.refreshInfo();
            handleClose();
        } else {
            handleOpenNumeInexistent();
        }
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }; 

  async function makeUpdate(idProfesor) {
    try {
        await axios.post("http://localhost:4000/api/modificaIdProfesor", {
            token: cookies.get("_auth"),
            idOra: props.idOra,
            idProfesor: idProfesor
        });
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} color='success' variant='contained' style={{paddingLeft: "12px", paddingRight: "12px"}}>Modifică profesor oră</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Introdu nume profesor</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmitNume}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Nume profesor nou"
                    defaultValue={props.name}
                    autoFocus
                />
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button onClick={handleOpenIntroduEmail} style={{marginTop: "5px"}}>Introdu email</Button>
                <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                    <Button onClick={handleClose} variant='text'>Închide</Button>
                    <Button type="submit" variant="contained"> Ok </Button>
                </Box>
              </div>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openNumeInexistent}
        onClose={handleCloseNumeInexistent}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Nume/email inexistent</DialogTitle>
        <DialogContent dividers={true}>
            <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                <Button onClick={handleCloseNumeInexistent} variant='text'>Ok</Button>
            </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={introduEmail}
        onClose={handleCloseIntroduEmail}
        scroll={'paper'}
      >
        {props.preaMulteNume ? 
            <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>
                Există mai mulți profesori cu numele introdus. Vă rugăm să introduceți email-ul profesorului.
            </DialogTitle>
            :
            <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>
                Introdu email-ul profesorului.
            </DialogTitle>
        }
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmitEmail}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Email profesor nou"
                    type="email"
                    autoFocus
                />
              <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                  <Button onClick={handleCloseIntroduEmail} variant='text'>Închide</Button>
                  <Button type="submit" variant="contained"> Ok </Button>
              </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}