import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import DialogAddNote from './popUpAdd';
import MakeInfoPopUpInfo from './makeInfoPopUpInfo';

export default function DialogInfo(props) {
  const [open, setOpen] = React.useState(false);
  const [otherInfoAdded, setOtherInfoAdded] = React.useState([]);

  function makeGoodDate(x) {
    return x.slice(8, 10) + "/" + x.slice(5, 7) + "/" + x.slice(0, 4);
  }

  function makeInfo(info) {
    return (
      <div key = {info.id}>
        {props.type == "absenta" ? 
          <MakeInfoPopUpInfo id={info.id} type={props.type} datapus={info.datapus} stare={info.stare} /> : 
          <MakeInfoPopUpInfo valoare={info.valoare} id={info.id} type={props.type} datapus={info.datapus} stare={info.stare} /> 
        }
      </div>
    )
  }

  const addToOtherInfo = async (datapus, stare, valoare, id) => {
    const newObject = {
      id: id,
      valoare: valoare,
      datapus: datapus,
      stare: stare
    }

    setOtherInfoAdded(otherInfoAdded => [...otherInfoAdded, newObject]);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    props.makeRefresh();
    setOpen(false);
    setOtherInfoAdded([]);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px"}}>Modifică {props.type}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Modifică {props.type}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText component="div">
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "3px"}}>
              {props.info.map(makeInfo)}
              {otherInfoAdded.length ? otherInfoAdded.map(makeInfo) : null}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogAddNote idElev={props.idElev} nume={props.nume} addInfo={addToOtherInfo} name={props.type} />
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}