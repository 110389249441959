import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import InfotainmentOrar from "../cmp/infotainmentOrar";

function OrarTab(props) {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <InfotainmentOrar />
        <MakeFooter />
      </div>
    );
}

export default OrarTab;
   