import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function DialogModificaProfesor(props) {
  const [open, setOpen] = React.useState(false);
  const [openEmailExistent, setOpenEmailExistent] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    handleCloseEmailExistent();
    setOpen(false);
  };

  function handleOpenEmailExistent() {
    setOpenEmailExistent(true);
  }

  function handleCloseEmailExistent() {
    setOpenEmailExistent(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post("http://localhost:4000/api/gasesteProfEmail", {
            token: cookies.get("_auth"),
            mailProf: event.target.email.value
        });
        if ( response.data.id >= 0 && response.data.id != props.id ) {
            handleOpenEmailExistent();
        } else {
            await axios.post("http://localhost:4000/api/makeNewAcc", {
                token: cookies.get("_auth"),
                mail: event.target.email.value
            });

            await axios.post("http://localhost:4000/api/modificaProfesor", {
                token: cookies.get("_auth"),
                mail: event.target.email.value,
                nume: event.target.name.value,
                idProfesor: props.id
            });

            props.refreshInfo();
            handleClose();
        }
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }; 

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} color='success' variant='contained' style={{paddingLeft: "12px", paddingRight: "12px"}}>Modifică profesor</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Modifică profesor</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmit}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Nume nou"
                    defaultValue={props.name}
                    autoFocus
                />
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="email"
                    label="Email nou"
                    defaultValue={props.mail}
                    style={{marginTop: "8px"}}
                />
                <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                    <Button onClick={handleClose} variant='text'>Închide</Button>
                    <Button type="submit" variant="contained"> Ok </Button>
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openEmailExistent}
        onClose={handleCloseEmailExistent}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Acest email are deja un cont de profesor atribuit</DialogTitle>
        <DialogContent dividers={false}>
            <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                <Button onClick={handleCloseEmailExistent} variant='text'>Ok</Button>
            </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}