import React from "react";
import Button from '@mui/material/Button';
import "../cssFiles/basicStyles.css"
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

function MakeCardAddOra(props) {
    const cookies = new Cookies;
    const navigate = useNavigate();

    async function makePress() {
        try {
            await axios.post(
                "http://localhost:4000/api/addOrar", {
                    token: cookies.get("_auth"),
                    idClasa: props.idClasa,
                    zi: props.zi
                }
            );

            props.refreshInfo();
          } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    return (
        <Button variant="contained" color="success" onClick={makePress}><p className="letterStyle">Adaugă oră</p></Button>
    );
}

export default MakeCardAddOra;