import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import { Container } from "@mui/material";

function ServiciiTab() {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <Container>
          <div style={{display: "flex", flexDirection: "column", marginTop: "4vh", gap: "15px"}}>
          <h2 className="letterStyle" style={{marginBottom: "10px"}}>Servicii</h2>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Suntem o echipă flexibilă, focusată pe cererile utilizatorilor si pregătită să adapteze platforma nevoilor acestora. Platforma noastră oferă un pachet complet de administrare a unei școli, nu doar un simplu catalog virtual. Am conectat sistemul nostru la Google Classroom pentru a oferi posibilitatea elevilor și profesorilor nu doar de a utiliza aplicația, ci și de a face meeting-uri și de a crea si completa teme și task-uri noi. Pe tot parcursul creării proiectului ne-am axat pe <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>EFICIENTIZARE</span> și <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>SIMPLITATE</span>.</p>
          
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Astfel, o școală ce folosește catalogul nostru beneficiază de următoarele unelte: </p>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru directori</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Acces la notele tuturor elevilor.</li>
            <li style={{marginLeft: "5px"}}>Generarea <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>CATALOGULUI FIZIC</span> în format PDF, ce va putea fi printat la sfârșitul anului!</li>
            <li style={{marginLeft: "5px"}}>Generarea rapoartelor folositoare ca raportul mediilor sau al absențelor în format PDF, pentru fiecare clasă!</li>
            <li style={{marginLeft: "5px"}}>Generarea foii matricole a fiecărui elev pe baza informațiilor introduse în catalog.</li>
            <li style={{marginLeft: "5px"}}>Accesarea si generarea orarului pentru fiecare clasă.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru administratori</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Vizualizarea tuturor claselor școlii.</li>
            <li style={{marginLeft: "5px"}}>Vizualizarea tuturor elevilor școlii.</li>
            <li style={{marginLeft: "5px"}}>Vizualizarea tuturor profeorilor școlii.</li>
            <li style={{marginLeft: "5px"}}>Vizualizarea tuturor sălilor de clasa ale școlii.</li>
            <li style={{marginLeft: "5px"}}>Adăugarea de săli/clase/ore/elevi/profesori noi.</li>
            <li style={{marginLeft: "5px"}}>Modificarea numelui unei săli/clase/ore/elev/profesor.</li>
            <li style={{marginLeft: "5px"}}>Modificarea orarului unei clase.</li>
            <li style={{marginLeft: "5px"}}>Modificarea începutului orelor si a lungimii acestora.</li>
            <li style={{marginLeft: "5px"}}>Accesarea si generarea programului pentru fiecare clasă sau sală.</li>
            <li style={{marginLeft: "5px"}}>Generarea automată și descărcarea condicii de prezență.</li>
            <li style={{marginLeft: "5px"}}>Transferarea elevilor dintr-o clasă în alta.</li>
            <li style={{marginLeft: "5px"}}>Modificarea profesorului care predă la o anumită oră.</li>
            <li style={{marginLeft: "5px"}}>Modificarea dirigintelui unei clase.</li>
            <li style={{marginLeft: "5px"}}>De asemenea, toate schimbările realizate de un administrator pe platforma noastră au un impact direct si asupra aplicației Google Clasroom.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru profesori</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Un sistem rapid de atribuire a absențelor. Cu un <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>SINGUR CLICK</span>, profesorii pot pune absențe tuturor elevilor ce nu sunt prezenți la ora!</li>
            <li style={{marginLeft: "5px"}}>Vederea datelor de contact. Toți profesorii au accesul la datele de contact ale fiecărui elev căruia îi predau.</li>
            <li style={{marginLeft: "5px"}}>Vizualizarea programului fiecărei zile, generat pe baza orarelor claselor.</li>
            <li style={{marginLeft: "5px"}}>Contactarea părinților prin mesaje <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>DIRECT DE PE PLATFORMĂ!</span></li>
            <li style={{marginLeft: "5px"}}>Motivarea, ștergerea și adăugarea notelor și absențelor.</li>
            <li style={{marginLeft: "5px"}}>Acces la un cont Google Clasroom, în care pot fi adăugate teme si făcute meeting-uri la fiecare oră.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru diriginți</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Accesarea si generarea orarului si al catalogului/statisticilor clasei lor.</li>
            <li style={{marginLeft: "5px"}}>În plus față de uneltele precizate mai sus, diriginții beneficiaza de sistemul nostru de <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>MOTIVARE A ABSENȚELOR</span>. Aceștia trebuie doar să introducă data de început și cea de sfârșit a intervalului de motivare, iar programul se ocupă de restul!</li>
            <li style={{marginLeft: "5px"}}>De asemenea, diriginții au acces și la alte informații despre elevi, cum ar fi notele de la celelalte materii și statistici despre clasa.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru părinți/elevi</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Primirea săptamanala de <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>ALERTE</span>, în cazul în care elevul/eleva are note/absențe nou introduse în catalog.</li>
            <li style={{marginLeft: "5px"}}>Vizualizarea și descărcarea orarului clasei.</li>
            <li style={{marginLeft: "5px"}}>Vederea unui <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>ISTORIC</span> ce prezintă toate acțunile făcute de către staff-ul școlii: când și ce note/absențe au fost puse, când au fost motivate absențele sau când au fost șterse.</li>
            <li style={{marginLeft: "5px"}}>Accesarea statisticilor ca nota medie si numărul de absențe mediu ale clasei și școlii la fiecare materie.</li>
            <li style={{marginLeft: "5px"}}>Acces la contul Google Clasroom, de unde elevi pot intra on-line la ore si trimite teme.</li>
          </ul>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px", marginBottom: "45px"}}>De asemenea, am considerat siguranța datelor ca fiind unul dintre principalele scopuri ale website-ului. Astfel, <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>ZILNIC</span> facem un back-up cu toate notele introduse, pentru ca acestea să poată fi restaurate, în cazul în care va fi nevoie.</p>
         </div>
        </Container>
        <MakeFooter />
      </div>
    );
}

export default ServiciiTab;

/*
import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import { Container } from "@mui/material";

function ServiciiTab() {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <Container>
          <div style={{display: "flex", flexDirection: "column", marginTop: "4vh", gap: "15px"}}>
          <h2 className="letterStyle" style={{marginBottom: "10px"}}>Servicii</h2>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Platforma noastră se axează pe <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>EFICIENTIZARE</span> și <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>SIMPLITATE</span>. Suntem o echipă flexibilă, focusată pe cererile utilizatorilor si pregatită să adapteze platforma nevoilor acestora.</p>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px"}}>Am încercat să facilitam informații folositoare elevilor si profesorilor într-o manieră ușor de urmărit. Astfel, o școala ce folosește catalogul nostru beneficiaza de următoarele unelte: </p>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru directori</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Acces la notele tuturor elevilor.</li>
            <li style={{marginLeft: "5px"}}>Generarea <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>CATALOGULUI FIZIC</span> în format PDF, ce va putea fi printat la sfârșitul anului!</li>
            <li style={{marginLeft: "5px"}}>Generarea rapoartelor folositoare ca raportul mediilor sau al absențelor în format PDF, pentru fiecare clasă!</li>
            <li style={{marginLeft: "5px"}}>Generarea foii matricole a fiecărui elev pe baza informațiilor introduse în catalog.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru administratori</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Vizualizarea tuturor claselor școlii.</li>
            <li style={{marginLeft: "5px"}}>Modificarea numelui unei clase/ore/elev.</li>
            <li style={{marginLeft: "5px"}}>Modificarea orarelor claselor.</li>
            <li style={{marginLeft: "5px"}}>Generarea automată și descărcarea condicii de prezență.</li>
            <li style={{marginLeft: "5px"}}>Transferarea elevilor dintr-o clasă în alta.</li>
            <li style={{marginLeft: "5px"}}>Modificarea profesorului care predă la o anumită oră.</li>
            <li style={{marginLeft: "5px"}}>Modificarea dirigintelui unei clase.</li>
            <li style={{marginLeft: "5px"}}>Adăugarea de clase și ore noi.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru profesori</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Un sistem rapid de atribuire a absențelor. Cu un <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>SINGUR CLICK</span>, profesorii pot pune absențe tuturor elevilor ce nu sunt prezenți la ora!</li>
            <li style={{marginLeft: "5px"}}>Vederea datelor de contact. Toți profesorii au accesul la datele de contact ale fiecărui elev căruia îi predau.</li>
            <li style={{marginLeft: "5px"}}>Vizualizarea programului fiecărei zile, generat pe baza orarelor claselor.</li>
            <li style={{marginLeft: "5px"}}>Contactarea părinților prin mesaje <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>DIRECT DE PE PLATFORMĂ!</span></li>
            <li style={{marginLeft: "5px"}}>Motivarea, ștergerea și adăugarea notelor și absențelor.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru diriginți</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>În plus față de uneltele precizate mai sus, diriginții beneficiaza de sistemul nostru de <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>MOTIVARE A ABSENȚELOR</span>. Aceștia trebuie doar să introducă data de început și cea de sfârșit a intervalului de motivare, iar programul se ocupă de restul!</li>
            <li style={{marginLeft: "5px"}}>De asemenea, diriginții au acces și la alte informații despre elevi, cum ar fi notele de la celelalte materii și statistici despre clasa.</li>
          </ul>
          <h4 className="letterStyle" style={{paddingLeft: "15px"}}>Pentru părinți/elevi</h4>
          <ul>
            <li style={{marginLeft: "5px"}}>Primirea săptamanala de <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>ALERTE</span>, în cazul în care elevul/eleva are note/absențe nou introduse în catalog.</li>
            <li style={{marginLeft: "5px"}}>Vizualizarea și descărcarea orarului clasei.</li>
            <li style={{marginLeft: "5px"}}>Vederea unui <span style={{fontWeight: "600", fontSize: "18px", color: "#9a6500"}}>ISTORIC</span> ce prezintă toate acțunile făcute de către staff-ul școlii: când și ce note/absențe au fost puse, când au fost motivate absențele sau când au fost șterse.</li>
            <li style={{marginLeft: "5px"}}>Accesarea statisticilor ca nota medie si numarul de absențe mediu ale clasei și școlii la fiecare materie.</li>
          </ul>
          <p className="letterStyle" style={{fontSize: "17px", paddingLeft: "15px", marginBottom: "45px"}}>De asemenea, am considerat siguranța datelor ca fiind unul dintre principalele scopuri ale website-ului. Astfel, <span style={{fontWeight: "600", fontSize: "18px", color: "#003aad"}}>ZILNIC</span> facem un back-up cu toate notele introduse, pentru ca acestea să poată fi restaurate, în cazul în care va fi nevoie.</p>
         </div>
        </Container>
        <MakeFooter />
      </div>
    );
}

export default ServiciiTab;
   */
   