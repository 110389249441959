import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function DialogSchimbaInfOra(props) {
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        await axios.post("http://localhost:4000/api/modificaOra", {
            token: cookies.get("_auth"),
            idOra: props.idOra,
            numeora: event.target.name.value
        });
        props.refreshInfo();

        handleClose();
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }; 

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} color='success' variant='contained' style={{paddingLeft: "55px", paddingRight: "55px"}}>Modifică ora</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Modifică nume clasă</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmit}>
                <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Nume nou"
                    defaultValue={props.name}
                    autoFocus
                />
              <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                  <Button onClick={handleClose} variant='text'>Închide</Button>
                  <Button type="submit" variant="contained"> Ok </Button>
              </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}