import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import DialogSchimbaNumeClasa from "./popUpSchimbaNumeClasa";
import DialogSchimbaInfOra from "./popUpSchimbaInfOra";
import DialogSchimbaProfesorOra from "./popUpSchimbaProfesorOra";
import DialogModificaSala from "./popUpModificaSala";
import fontkit from "@pdf-lib/fontkit"

function MakeCardSala(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();

  async function stergeSala() {
    try {
        await axios.post(
            "http://localhost:4000/api/deleteSala", {
                token: cookies.get("_auth"),
                idSala: props.id
            }
        );

        props.refreshInfo();
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  const downloadOrar = async () => {
    try {
      const response = await axios.post("http://localhost:4000/api/getOrarSala", {
        token: cookies.get("_auth"),
        idSala: props.id
      });

      var oreTotal = response.data.orar;

      oreTotal.sort((a, b) => {
        var xTimpIncOra = a.timpinceputora;
        var xTimpIncMin = a.timpinceputminut;
        var yTimpIncOra = b.timpinceputora;
        var yTimpIncMin = b.timpinceputminut;

        if ( xTimpIncOra > yTimpIncOra ) {
          return 1;
        }
        if ( xTimpIncOra < yTimpIncOra ) {
          return -1;
        }

        if ( xTimpIncMin > yTimpIncMin ) {
          return 1;
        }
        if ( xTimpIncMin < yTimpIncMin ) {
          return -1;
        }
        return 0;
      });

      var realOre = [];
      var maxLine = 0;
      
      for ( var i = 0; i < oreTotal.length; i++ ) {
        if ( i == 0 || oreTotal[i].timpinceputminut != oreTotal[i - 1].timpinceputminut || oreTotal[i].timpinceputora != oreTotal[i - 1].timpinceputora ) {
          maxLine++;
          realOre.push(oreTotal[i]);
        }
      }

      for ( var i = 0; i < oreTotal.length; i++ ) {
        for ( var i2 = 0; i2 < realOre.length; i2++ ) {
          if ( oreTotal[i].timpinceputminut == realOre[i2].timpinceputminut && oreTotal[i].timpinceputora == realOre[i2].timpinceputora ) {
            oreTotal[i].indexora = i2;
          }
        }
      }

      const url = 'http://localhost:4000/orarSmall.pdf';
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
      
      const ubuntuFontBytes = await fetch(
        'http://localhost:4000/niceFont.ttf',
      ).then((res) => res.arrayBuffer());

      const finalPdf = await PDFDocument.load(existingPdfBytes);
      finalPdf.registerFontkit(fontkit);
      const goodFont = await finalPdf.embedFont(ubuntuFontBytes);

      const page = finalPdf.getPage(0);

      ///y = 595
      ///x = 840

      ///x = 20 (start tabel)

      const numeClasa = props.name;
      {
      const textWidth = goodFont.widthOfTextAtSize(numeClasa, 33);

      page.drawText(numeClasa, {
        x: Math.floor((840 - textWidth) / 2),
        y: 550,
        font: goodFont,
        size: 33,
        color: rgb(0, 0, 0),
        lineHeight: 24,
        opacity: 0.75,
      });
      }

      if ( maxLine ) {
        var y = [532, 481, 388, 295, 207, 120, 30];
        var x = [];
        var startX = 104;
        var endX = 820;
        var pas = Math.floor((endX - startX + 1) / maxLine);
        var hasTime = [];

        for ( var i = 0; i <= 6; i++ ) {
          page.drawLine({
            start: { x: 20, y: y[i]},
            end: { x: startX + pas * maxLine, y: y[i]},
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 0.75
          });
        }

        x.push(startX);
        for ( var i = 0; i < maxLine; i++ ) {
          hasTime.push(false);
          x.push(startX + pas * (i + 1));
          page.drawLine({
            start: { x: startX + pas * (i + 1), y: y[0]},
            end: { x: startX + pas * (i + 1), y: y[6]},
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 0.75
          });

          const textWidth = goodFont.widthOfTextAtSize((i + 1).toString(), 20);
          page.drawText((i + 1).toString(), {
            x: startX + pas * i + Math.floor((pas - textWidth) / 2),
            y: y[0] - 20,
            font: goodFont,
            size: 20,
            color: rgb(0, 0, 0),
            lineHeight: 24,
            opacity: 0.75,
          });
        }

        //form.getTextField("clasa").setText(numeClasa);
        for ( var i = 0; i < oreTotal.length; i++ ) {
          if ( !hasTime[oreTotal[i].indexora] ) {
            var aux = [oreTotal[i].timpinceputora.toString(), oreTotal[i].timpinceputminut.toString(), oreTotal[i].timpsfarsitora.toString(), oreTotal[i].timpsfarsitminut.toString()];
            for ( var i2 = 0; i2 < 4; i2++ ) {
              if ( aux[i2].length == 1 ) {
                aux[i2] = "0" + aux[i2];
              }
            }

            const textWidth = goodFont.widthOfTextAtSize(aux[0] + ":" + aux[1] + " - " + aux[2] + ":" + aux[3], 8);
            page.drawText(aux[0] + ":" + aux[1] + " - " + aux[2] + ":" + aux[3], {
              x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2),
              y: y[0] - 45,
              font: goodFont,
              size: 8,
              color: rgb(0, 0, 0),
              lineHeight: 24,
              opacity: 0.75,
            });   
            hasTime[oreTotal[i].indexora] = true;         
          }

          var textSize = 17;
          var textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeclasa, textSize);

          while ( textWidth >= pas - 3 ) {
            textSize--;
            textWidth = goodFont.widthOfTextAtSize(oreTotal[i].numeclasa, textSize);
          }

          page.drawText(oreTotal[i].numeclasa, {
            x: startX + pas * oreTotal[i].indexora + Math.floor((pas - textWidth) / 2),
            y: y[oreTotal[i].zi + 1] - 50,
            font: goodFont,
            size: textSize,
            color: rgb(0, 0, 0),
            lineHeight: 24,
            opacity: 0.75,
          });
        }
      }

      const pdfBytes = await finalPdf.save();

      const blob = new Blob([pdfBytes]);
      const fileUrl = window.URL.createObjectURL(blob);
  
      let alink = document.createElement("a");
      alink.href = fileUrl;
      alink.download = "Orar" + "_" + numeClasa + ".pdf";
      alink.click();
    } catch(err) {
      cookies.remove("_auth");
      navigate("/");
    }
  }
  
  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{display: "flex", flexDirection: "column", gap: "4px", justifyContent: "center"}}>
            <div style={{ display: "flex", alignItems: "center", gap: "6px"}}>
               <h3 className="letterStyle">{props.name}</h3>
            </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            gap: "3px",
            paddingRight: "17px"
          }}
        >
            <DialogModificaSala
                idSala={props.id}
                name={props.name}
                refreshInfo={props.refreshInfo}
            />
            <Button onClick={downloadOrar} color='success' variant='contained'>Descarcă program sală</Button>
            <Button onClick={stergeSala} color='success' variant='contained' style={{paddingLeft: "66px", paddingRight: "66px"}}>Șterge sală</Button>
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardSala;
