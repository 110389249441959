import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function DialogAddNote(props) {
  const [open, setOpen] = React.useState(false);
  const currDate = new Date();
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const date = new Date(event.target.an.value + "-" + event.target.luna.value + "-" + event.target.zi.value);

    if ( !isNaN(date.getTime()) ) {
      try {
        var val = 0;

        if ( props.name == "note" ) {
          var aux;
          if ( event.target.valoare.value > 10 ) {
            aux = 10;
          } else if ( event.target.valoare.value < 1 ) {
            aux = 1;
          } else {
            aux = event.target.valoare.value;
          }

          val = Math.round(aux);
        }

        if ( props.name == "note" ) {
          const response = await axios.post("http://localhost:4000/api/addNota", {
            token: cookies.get("_auth"),
            idElev: props.idElev,
            nume: props.nume,
            data: event.target.an.value + "-" + event.target.luna.value + "-" + event.target.zi.value,
            valoare: val
          });
          date.setDate(date.getDate() - 1);

          props.addInfo(date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
          false,
          val,
          response.data.id);
        } else {
          const response = await axios.post("http://localhost:4000/api/addAbsenta", {
            token: cookies.get("_auth"),
            idElev: props.idElev,
            nume: props.nume,
            data: event.target.an.value + "-" + event.target.luna.value + "-" + event.target.zi.value,
          });
          date.setDate(date.getDate() - 1);

          props.addInfo(date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
          false,
          val,
          response.data.id);
        }

        handleClose();
      } catch(err) {
        cookies.remove("_auth");
        navigate("/");
      }
    } else {
      handleClose();
    }
  }; 

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>Adaugă {props.type}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Adaugă {props.name}</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmit}>
              <div style={{display: "flex", gap: "3px"}}>
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="zi"
                      label="Zi"
                      defaultValue={currDate.getDate()}
                  />
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Luna"
                      name="luna"
                      defaultValue={currDate.getMonth() + 1}
                  />
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="An"
                      name="an"
                      defaultValue={currDate.getFullYear()}
                  />
              </div>
              {props.name == "note" ? 
                  <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="valoare"
                      label="Valoare nota"
                      style={{marginTop: "8px"}}
                  /> : null
              }
              <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                  <Button onClick={handleClose} variant='text'>Închide</Button>
                  <Button type="submit" variant="contained"> Ok </Button>
              </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}