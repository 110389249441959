import React from 'react';
import {
  MDBFooter,
  MDBContainer
} from 'mdb-react-ui-kit';
import "../cssFiles/styles.css"
import MakeButton from './headerBtn';
import Cookies from 'universal-cookie';

function MakeFooter() {
    const currentYear = new Date().getFullYear();
    const [isLoggedIn, changeStateLogIn] = React.useState(false);
    const cookies = React.useMemo(() => {return new Cookies()}, []);

    React.useEffect(() => {
      if ( cookies.get("_auth") ) {
        changeStateLogIn(true);
      }
    }, [cookies]);

    return (
        <MDBFooter className='text-center text-white mt-auto blueFlag'>
        <MDBContainer className='p-2 pb-0'>
            <section className=''>
            <p className='d-flex justify-content-center align-items-center' style={{marginBottom: 0}}>
                {!isLoggedIn ? 
                <MakeButton loc="/" text="Despre noi"/>
                :
                <MakeButton loc="/home" text="Acasa" />
                }
                <MakeButton text="Servicii" loc="/servicii"/>
                <MakeButton text="Contactati-ne!" loc="/contact"/>
            </p>
            </section>
        </MDBContainer>

        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
            © {currentYear} Copyright:
            <a className='text-white' href='https://mdbootstrap.com/'>
            catalogulscolii.ro
            </a>
        </div>
        </MDBFooter>
    );
}

export default MakeFooter;