import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import MakeCard from "./colidbleBox";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

function IstoricBox() {
  const [istoric, changeIstoric] = React.useState([]);

  const sortAlfabeticVar = (x) => {
    var aux = x;

    if ( aux.length ) {
        aux.sort((a, b) => {
            let dataX = a.datapus.toLowerCase();
            let dataY = b.datapus.toLowerCase();

            if ( dataX < dataY ) {
                return 1;
            }
            if ( dataX > dataY ) {
                return -1;
            }

            let numeX = a.nume.toLowerCase();
            let numeY = b.nume.toLowerCase();

            if ( numeX > numeY ) {
                return 1;
            }
            if ( numeX < numeY ) {
                return -1;
            }

            let typeValX = a.typeval.toLowerCase();
            let typeValY = b.typeval.toLowerCase();

            if ( typeValX < typeValY ) {
                return 1;
            }
            if ( typeValX > typeValY ) {
                return -1;
            }

            let typeactiuneX = a.typeactiune;
            let typeactiuneY = b.typeactiune;

            if ( typeactiuneX > typeactiuneY ) {
                return 1;
            }
            if ( typeactiuneX < typeactiuneY ) {
                return -1;
            }

            let valoareX = a.valoare;
            let valoareY = b.valoare;

            if ( valoareX < valoareY ) {
                return 1;
            }
            if ( valoareX > valoareY ) {
                return -1;
            }

            let datavaloareX = a.datavaloare.toLowerCase();
            let datavaloareY = b.datavaloare.toLowerCase();

            if ( datavaloareX < datavaloareY ) {
                return 1;
            }
            if ( datavaloareX > datavaloareY ) {
                return -1;
            }

            return 0;
        });

        aux[0].isNewDate = true;
        for ( var i = 1; i < aux.length; i++ ) {
            if ( aux[i].datapus != aux[i - 1].datapus ) {
                aux[i].isNewDate = true;
            } else {
                aux[i].isNewDate = false;
            }
        }

        changeIstoric(aux);
    }
  }

  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const {id} = useParams();
  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
      if ( !cookies.get("_auth") ) {
          navigate("/");
      }

      async function testIntegrity() {
          try {
              const response = await axios.post(
                  "http://localhost:4000/api/getIstoric", {
                      token: cookies.get("_auth"),
                      idElev: id
                  }
              );

              sortAlfabeticVar(response.data.info);
          } catch(err) {
              cookies.remove("_auth");
              navigate("/");
          }
      }

      if ( oneTime ) {
        testIntegrity();
        setOneTime(false);
      }
    }, [cookies, navigate]);

    function makeGoodDate(x) {
        const dd = x.slice(8, 10);
        const mm = x.slice(5, 7);
        const yy = x.slice(0, 4);
        const d = new Date(yy + "-" + mm + "-" + dd);
        d.setDate(d.getDate() + 1);

        return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    }

  function makeIstoric(info) {
    if ( info.typeval == "nota" ) {
        if ( info.typeactiune == 0 ) {
            return (
                <div key={info.id}>
                    {info.isNewDate ? <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {makeGoodDate(info.datapus)} </h3> : null}
                    <p className="lettterStyle" style={{margin: "0 0 0 10px"}}><CircleIcon style={{fontSize: "8px", margin: "0 3px 2px 0"}}/>{info.nume}: <span style={{color: "green", fontWeight: "650"}}>Adăugare</span> <span style={{fontWeight: "650"}}>{info.valoare}</span> cu data de <span style={{fontWeight: "600"}}>{makeGoodDate(info.datavaloare)}</span></p>
                </div>
            );
        } else {
            return (
                <div key={info.id}>
                    {info.isNewDate ? <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {makeGoodDate(info.datapus)} </h3> : null}
                    <p className="lettterStyle" style={{margin: "0 0 0 10px"}}><CircleIcon style={{fontSize: "8px", margin: "0 3px 2px 0"}}/>{info.nume}: <span style={{color: "red", fontWeight: "650"}}>Ștergere</span> <span style={{fontWeight: "650"}}>{info.valoare}</span> cu data de <span style={{fontWeight: "600"}}>{makeGoodDate(info.datavaloare)}</span></p>
                </div>
            );
        }
    } else {
        if ( info.typeactiune == 0 ) {
            return (
                <div key={info.id}>
                    {info.isNewDate ? <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {makeGoodDate(info.datapus)} </h3> : null}
                    <p className="lettterStyle" style={{margin: "0 0 0 10px"}}><CircleIcon style={{fontSize: "8px", margin: "0 3px 2px 0"}}/>{info.nume}: <span style={{color: "green", fontWeight: "650"}}>Adăugare</span> absență cu data de <span style={{fontWeight: "600"}}>{makeGoodDate(info.datavaloare)}</span></p>
                </div>
            );
        } else if ( info.typeactiune == 1 ) {
            return (
                <div  key={info.id}>
                    {info.isNewDate ? <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {makeGoodDate(info.datapus)} </h3> : null}
                    <p className="lettterStyle" style={{margin: "0 0 0 10px"}}><CircleIcon style={{fontSize: "8px", margin: "0 3px 2px 0"}}/>{info.nume}: <span style={{fontWeight: "650"}}>Motivare</span> absență cu data de <span style={{fontWeight: "600"}}>{makeGoodDate(info.datavaloare)}</span></p>
                </div>
            );
        } else {
            return (
                <div key={info.id}>
                    {info.isNewDate ? <h3 className="letterStyle" style={{borderBottomColor: "grey", borderBottomStyle: "solid", marginTop:"10px", marginBottom:"10px"}}> {makeGoodDate(info.datapus)} </h3> : null}
                    <p className="lettterStyle" style={{margin: "0 0 0 10px"}}><CircleIcon style={{fontSize: "8px", margin: "0 3px 2px 0"}}/>{info.nume}: <span style={{color: "red", fontWeight: "650"}}>Ștergere</span> absență cu data de <span style={{fontWeight: "600"}}>{makeGoodDate(info.datavaloare)}</span></p>
                </div>
            );
        }
    }
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "0px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
        </div>
        <div style={{display: "flex", flexDirection: "column"}} >
            {istoric.map(makeIstoric)}
        </div>
    </Container>
  );
}

export default IstoricBox;
