import * as React from "react";
import Card from "@mui/material/Card";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";

function MakeCardAbsenta(props) {
    const [bckColor, changeBckColor] = React.useState("#478042");

    React.useEffect(() => {
      props.init(props.id);
    }, []);

    function makeClick() {
        if ( bckColor == "#478042" ) {
            changeBckColor("#8F2C37");
            props.callParent(props.id, true);
        } else {
            changeBckColor("#478042");
            props.callParent(props.id, false);
        }
    }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: bckColor
      }}
      onClick={makeClick}
    >
        <h3 className="letterStyle" style={{color: "white", userSelect: "none"}}>{props.name}</h3>
    </Card>
    </Container>
  );
}

export default MakeCardAbsenta;
