import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit"

function makeGoodDate(x) {
  const dd = x.slice(8, 10);
  const mm = x.slice(5, 7);
  const yy = x.slice(0, 4);
  const d = new Date(yy + "-" + mm + "-" + dd);
  d.setDate(d.getDate() + 1);

  return d.getDate() + "/" + (d.getMonth() + 1);
}

function MakeCardDirector(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const manageOnClick = () => {
    navigate("/clasaDirector/" + props.id);
  }

  const manageOnClickOrar = () => {
    navigate("/orarClasa/" + props.id);
  }

  const manageOnClickGenerateAbsente = async () => {
    try {
        const url = 'http://localhost:4000/raportAbsente.pdf';
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        
        const ubuntuFontBytes = await fetch(
          'http://localhost:4000/niceFont.ttf',
        ).then((res) => res.arrayBuffer());

        const finalPdf = await PDFDocument.create();

        const responseElevi = await axios.post(
          "http://localhost:4000/api/getAbsenteElevi", {
            token: cookies.get("_auth"),
            idClasa: props.id
          }
        );

        var elevi = responseElevi.data.dateElevi;

        elevi.sort((a, b) => {
          const numeA = a.nume.toLowerCase();
          const numeB = b.nume.toLowerCase();

          if ( numeA > numeB ) {
            return 1;
          }
          if ( numeA < numeB ) {
            return -1;
          }
          return 0;
        });

        for ( var i = 0; i < elevi.length; i += 30 ) {
          const newPageBytes = await fetch(url).then(res => res.arrayBuffer());
          const newPage = await PDFDocument.load(newPageBytes);
          newPage.registerFontkit(fontkit);
          const goodFont = await newPage.embedFont(ubuntuFontBytes);
          const form = newPage.getForm();

          form.getTextField('numeclasa').setText(responseElevi.data.numeClasa);

          for ( var i2 = 0; i2 < 30 && i2 + i < elevi.length; i2++ ) {
            form.getTextField('motivate' + (i2 + 1)).setText((elevi[i + i2].nrAbsente).toString());
            form.getTextField('nemotivate' + (i2 + 1)).setText((elevi[i + i2].nrAbsenteNemotivate).toString());
            form.getTextField('nume' + (i2 + 1)).setText(elevi[i + i2].nume);
          }

          form.updateFieldAppearances(goodFont);
          form.flatten();
          await newPage.save();
          const [firstPag] = await finalPdf.copyPages(newPage, [0]);
          finalPdf.addPage(firstPag);
          await finalPdf.save();
        }

        const pdfBytes = await finalPdf.save();

        const blob = new Blob([pdfBytes]);
        const fileUrl = window.URL.createObjectURL(blob);
    
        let alink = document.createElement("a");
        alink.href = fileUrl;
        alink.download = "RaportAbsenteClasa" + "_" + props.name + ".pdf";
        alink.click();
   } catch(err) {
      cookies.remove("_auth");
      navigate("/");
    }
  }

  const manageOnClickGenerateMedie = async () => {
    try {
        const url = 'http://localhost:4000/raportMedii.pdf';
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        
        const ubuntuFontBytes = await fetch(
          'http://localhost:4000/niceFont.ttf',
        ).then((res) => res.arrayBuffer());

        const finalPdf = await PDFDocument.create();

        const responseElevi = await axios.post(
          "http://localhost:4000/api/getMedieElevi", {
            token: cookies.get("_auth"),
            idClasa: props.id
          }
        );

        console.log(responseElevi);

        var elevi = responseElevi.data.dateElevi;

        elevi.sort((a, b) => {
          const numeA = a.nume.toLowerCase();
          const numeB = b.nume.toLowerCase();

          if ( numeA > numeB ) {
            return 1;
          }
          if ( numeA < numeB ) {
            return -1;
          }
          return 0;
        });

        for ( var i = 0; i < elevi.length; i += 30 ) {
          const newPageBytes = await fetch(url).then(res => res.arrayBuffer());
          const newPage = await PDFDocument.load(newPageBytes);
          newPage.registerFontkit(fontkit);
          const goodFont = await newPage.embedFont(ubuntuFontBytes);
          const form = newPage.getForm();

          form.getTextField('numeclasa').setText(responseElevi.data.numeClasa);

          for ( var i2 = 0; i2 < 30 && i2 + i < elevi.length; i2++ ) {
            form.getTextField('medie' + (i2 + 1)).setText((Math.round(100 * (elevi[i + i2].sumamaterii + elevi[i + i2].notapurtare) / elevi[i + i2].nrmaterii) / 100).toString());
            form.getTextField('nume' + (i2 + 1)).setText(elevi[i + i2].nume);
          }

          form.updateFieldAppearances(goodFont);
          form.flatten();
          await newPage.save();
          const [firstPag] = await finalPdf.copyPages(newPage, [0]);
          finalPdf.addPage(firstPag);
          await finalPdf.save();
        }

        const pdfBytes = await finalPdf.save();

        const blob = new Blob([pdfBytes]);
        const fileUrl = window.URL.createObjectURL(blob);
    
        let alink = document.createElement("a");
        alink.href = fileUrl;
        alink.download = "RaportMediiClasa" + "_" + props.name + ".pdf";
        alink.click();
   } catch(err) {
      cookies.remove("_auth");
      navigate("/");
    }
  }

  const manageOnClickGenerate = async () => {
    try {
        const url = 'http://localhost:4000/catalogCuScris.pdf';
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        
        const ubuntuFontBytes = await fetch(
          'http://localhost:4000/niceFont.ttf',
        ).then((res) => res.arrayBuffer());

        const finalPdf = await PDFDocument.create();

        const responseElevi = await axios.post(
          "http://localhost:4000/api/getClasaDirector", {
            token: cookies.get("_auth"),
            idClasa: props.id
          }
        );

        var elevi = responseElevi.data.dateElevi;

        elevi.sort((a, b) => {
          const numeA = a.nume.toLowerCase();
          const numeB = b.nume.toLowerCase();

          if ( numeA > numeB ) {
            return 1;
          }
          if ( numeA < numeB ) {
            return -1;
          }
          return 0;
        });

        for ( var i = 0; i < elevi.length; i += 3 ) {
          const newPageBytes = await fetch(url).then(res => res.arrayBuffer());
          const newPage = await PDFDocument.load(newPageBytes);
          newPage.registerFontkit(fontkit);
          const goodFont = await newPage.embedFont(ubuntuFontBytes);
          const form = newPage.getForm();

          for ( var i2 = 0; i2 < 3 && i2 + i < elevi.length; i2++ ) {
            const newElev = await axios.post(
              "http://localhost:4000/api/getElev", {
                token: cookies.get("_auth"),
                idElev: elevi[i + i2].id
              }
            )

            const notaPurtare = newElev.data.notaPurtare;
            var infoOre = newElev.data.ore;
            const nrAbsente = newElev.data.nrAbsente;
            const nrAbsenteNemotivate = newElev.data.nrAbsenteNemotivate;

            form.getTextField('purtare'+ (i2 + 1)).setText(notaPurtare.toString(), );
            form.getTextField('totalabsente' + (i2 + 1)).setText(nrAbsente.toString());
            form.getTextField('motivate' + (i2 + 1)).setText((nrAbsenteNemotivate).toString());

            infoOre.sort((a, b) => {
              const numeA = a.nume.toLowerCase();
              const numeB = b.nume.toLowerCase();
    
              if ( numeA > numeB ) {
                return 1;
              }
              if ( numeA < numeB ) {
                return -1;
              }
              return 0;
            });

            if ( i2 == 0 ) {
              for ( var i3 = 0; i3 < infoOre.length; i3++ ) {
                const newMaterie = form.getTextField('materie' + (i3 + 1));

                newMaterie.setText(infoOre[i3].nume);
              }
            }

            const numeElev = form.getTextField('nume' + (i2 + 1));
            numeElev.setText(elevi[i + i2].nume);

            for ( var i3 = 0; i3 < infoOre.length; i3++ ) {
              infoOre[i3].note.sort((a, b) => {
                const dataA = a.datapus;
                const dataB = b.datapus;

                if ( dataA > dataB ) {
                  return -1;
                }
                if ( dataA < dataB ) {
                  return 1;
                }
                return 0;
              });
              infoOre[i3].absente.sort((a, b) => {
                const dataA = a.datapus;
                const dataB = b.datapus;

                if ( dataA > dataB ) {
                  return -1;
                }
                if ( dataA < dataB ) {
                  return 1;
                }
                return 0;
              });

              var noteToPut = [];
              var sumaNote = 0;

              for ( var i4 = 0; i4 < infoOre[i3].note.length; i4++ ) {
                sumaNote += infoOre[i3].note[i4].valoare;

                noteToPut.push(makeGoodDate(infoOre[i3].note[i4].datapus));
                noteToPut.push(infoOre[i3].note[i4].valoare.toString());
              }

              form.getTextField('note' + (i2 + 1) + '_' + (i3 + 1)).setText(noteToPut.join('\n'));
              if ( sumaNote ) {
                form.getTextField('medie' + (i2 + 1) + '_' + (i3 + 1)).setText((Math.round(sumaNote / infoOre[i3].note.length)).toString());
                if ( Math.round(sumaNote / infoOre[i3].note.length) >= 5 ) {
                  form.getTextField('medieanuala' + (i2 + 1) + '_' + (i3 + 1)).setText((Math.round(sumaNote / infoOre[i3].note.length)).toString());
                }
              }

              var absenteToPut = [];
              for ( var i4 = 0; i4 < infoOre[i3].absente.length; i4++ ) {
                absenteToPut.push(makeGoodDate(infoOre[i3].absente[i4].datapus));
              }

              form.getTextField('absente' + (i2 + 1) + '_' + (i3 + 1)).setText(absenteToPut.join('\n'));
            }
          }

          form.updateFieldAppearances(goodFont);
          form.flatten();

          await newPage.save();

          const [firstPag] = await finalPdf.copyPages(newPage, [0]);
          const [secondPag] = await finalPdf.copyPages(newPage, [1]);

          finalPdf.addPage(firstPag);
          finalPdf.addPage(secondPag);

          await finalPdf.save();
        }

        const pdfBytes = await finalPdf.save();

        const blob = new Blob([pdfBytes]);
        const fileUrl = window.URL.createObjectURL(blob);
    
        let alink = document.createElement("a");
        alink.href = fileUrl;
        alink.download = "CatalogClasa" + "_" + props.name + ".pdf";
        alink.click();
   } catch(err) {
      cookies.remove("_auth");
      navigate("/");
    }
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center"}}>
          <h3 className="letterStyle">{props.name}</h3>
          {props.numeDirig ? 
            <h5 className="letterStyle" style={{paddingLeft: "8px", paddingTop: "5px"}}>{props.numeDirig}</h5>
            :
            null
          }
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "9px",
            gap: "3px"
          }}
        >
            <Button onClick = {manageOnClickGenerate} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px", paddingLeft: "48px", paddingRight: "47px"}}><p className="letterStyle">Generează catalog</p></Button>
            <Button onClick = {manageOnClickGenerateMedie} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px", paddingLeft: "30px", paddingRight: "29px"}}><p className="letterStyle">Generează raport medii</p></Button>
            <Button onClick = {manageOnClickGenerateAbsente} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px"}}><p className="letterStyle">Generează raport absențe</p></Button>
            <Button onClick = {manageOnClick} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px", paddingLeft: "87px", paddingRight: "87px"}}><p className="letterStyle">Vezi clasa</p></Button>
            <Button onClick = {manageOnClickOrar} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px", paddingLeft: "90px", paddingRight: "90px"}}><p className="letterStyle">Vezi orar</p></Button>
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardDirector;
