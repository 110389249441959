import * as React from 'react';
import YouSureDialog from "./popUpYouSure";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import "../cssFiles/basicStyles.css";
import axios from 'axios';

export default function MakeInfoPopUpInfo(props) {
    const [canBeSeen, setSeen] = React.useState(false);
    const [isMotivata, setMotivata] = React.useState(false);
    const cookies = new Cookies();
    const navigate = useNavigate();

    function makeGoodDate(x) {
        const dd = x.slice(8, 10);
        const mm = x.slice(5, 7);
        const yy = x.slice(0, 4);
        const d = new Date(yy + "-" + mm + "-" + dd);
        d.setDate(d.getDate() + 1);

        return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    }

    const makeCallErase = async () => {
      try {
        if ( props.type == "absente" ) {
          await axios.post(
            "http://localhost:4000/api/stergeAbsenta", {
              token: cookies.get("_auth"),
              idAbsenta: props.id
            }
          );
        } else {
          await axios.post(
            "http://localhost:4000/api/stergeNota", {
              token: cookies.get("_auth"),
              idNota: props.id
            }
          )
        }

        setSeen(true);
      } catch(err) {
        cookies.remove("_auth");
        navigate("/");
      }
    }

    const makeCallMotiveaza = async () => {
      try {
        await axios.post(
          "http://localhost:4000/api/motiveazaAbsenta", {
            token: cookies.get("_auth"),
            idAbsenta: props.id
          }
        )

        setMotivata(true);
      } catch(err) {
        cookies.remove("_auth");
        navigate("/");
      }
    }

    return (
      <div hidden={canBeSeen} key = {props.id} style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "3px"}}>
        <YouSureDialog makeCall={makeCallErase} typeOfAction={true} />
        {props.type == "absente" ? <p className='letterStyle' style={{marginRight: "3px"}}>{makeGoodDate(props.datapus)}</p> : <p className='letterStyle' style={{marginRight: "3px"}}>{makeGoodDate(props.datapus)}: {props.valoare}</p>}
        {props.type == "absente" ? (!props.stare && !isMotivata ? <YouSureDialog makeCall={makeCallMotiveaza} typeOfAction={false} text="Motivează" /> : <p className='letterStyle greenColor'style={{marginLeft: "5px"}}>Motivată</p>) : null}
      </div>
    )
  }
