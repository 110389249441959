import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import InfotainmentElev from "../cmp/infotainmentElev";

function ElevTab(props) {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <InfotainmentElev />
        <MakeFooter />
      </div>
    );
}

export default ElevTab;
   