import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import InfotainmentProgram from "../cmp/infotainmentProgram";

function ProgramTab(props) {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <InfotainmentProgram />
        <MakeFooter />
      </div>
    );
}

export default ProgramTab;
   