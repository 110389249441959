import React from "react";
import MakeHeader from "../cmp/header";
import MakeFooter from "../cmp/footer";
import InfotainmentPrezenta from "../cmp/infotainmentPrezenta";
import IstoricBox from "../cmp/istoricBox";

function IstoricTab(props) {
    return (
    <div className="d-flex flex-column min-vh-100">
        <MakeHeader />
        <IstoricBox />
        <MakeFooter />
      </div>
    );
}

export default IstoricTab;
   