import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import MakeCardSalaPopUp from './boxSalaPopUp';

export default function DialogModificaSalaOrar(props) {
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [sali, setSali] = React.useState([]);

  async function handleClickOpen() {
    setOpen(true);
    async function testIntegrity() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getSali", {
                    token: cookies.get("_auth"),
                }
            );

            sortAlfabetic(response.data.sali);
          } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    await testIntegrity();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sortAlfabetic = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.numesala.toLowerCase();
      let y = b.numesala.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setSali(aux);
  }

  async function updateInfo(idSala) {
    try {
        await axios.post(
            "http://localhost:4000/api/modificaSalaOrar", {
                token: cookies.get("_auth"),
                idOra: props.idOrar,
                idSala: idSala
            }
        );

        props.refreshInfo();
        handleClose();
      } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  function makeCardSala(sala) {
    return (
        <MakeCardSalaPopUp
            key = {sala.id}
            id = {sala.id}
            nume = {sala.numesala}
            makeUpdate = {updateInfo}
        />
    )
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} color='success' variant='contained' style={{paddingLeft: "78px", paddingRight: "78px"}}>Modifică sală</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Alege sală nouă</DialogTitle>
        <DialogContent dividers={true}>
        <div style={{display: "flex", flexDirection: "column", gap: "3px"}}>
          <MakeCardSalaPopUp 
            id = {1}
            nume = {'Nicio sală'}
            makeUpdate = {updateInfo}
          />
          {sali.map(makeCardSala)}
        </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}