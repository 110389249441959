import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import DialogSchimbaNumeClasa from "./popUpSchimbaNumeClasa";
import DialogSchimbaInfOra from "./popUpSchimbaInfOra";
import DialogSchimbaProfesorOra from "./popUpSchimbaProfesorOra";
import DialogModificaProfesor from "./popUpModificaProfesor";
import DialogModificaLungimeOre from "./popUpModificaLungimeOre";

function MakeCardLungimeOre(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
            <h4 className="letterStyle">Lungime ore: {props.lungimeOra} minute</h4>
            <h4 className="letterStyle">Lungime pauză: {props.lungimePauza} minute</h4>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "3px",
            paddingRight: "17px"
          }}
        >
            <DialogModificaLungimeOre
                lungimeOra={props.lungimeOra}
                lungimePauza={props.lungimePauza}
                refreshInfo={props.refreshInfo}
            />
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardLungimeOre;
