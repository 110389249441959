import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import DialogInfo from "./popUpInfo";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function MakeCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [showSmallInfo, setSmallInfo] = React.useState(window.innerWidth > 500);

  React.useEffect(() => {
    props.makeCallback(setExpanded);
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function makeGoodDate(x) {
    const dd = x.slice(8, 10);
    const mm = x.slice(5, 7);
    const yy = x.slice(0, 4);
    const d = new Date(yy + "-" + mm + "-" + dd);
    d.setDate(d.getDate() + 1);

    return d.getDate() + "/" + (d.getMonth() + 1);
  }

  function makeNotes(note) {
    return (<p key = {note.id} className="letterStyle greenColor">{makeGoodDate(note.datapus) + ": " + note.valoare}</p>);
  }

  function makeAbsente(absenta) {
    if ( absenta.stare ) {
      return (<p key = {absenta.id} className="letterStyle greenColor">{makeGoodDate(absenta.datapus)}</p>);
    } else {
      return (<p key = {absenta.id} className="letterStyle redColor">{makeGoodDate(absenta.datapus)}</p>);
    }
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between"
        }}
      >
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%"}}>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <h3 className="letterStyle">{props.name}</h3>
            <p className="letterStyle" style={{ marginTop: "7px" }}>
              {props.subtitle}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "15px"
              }}
            >
            { true ? <h6 className="letterStyle">Medie: {props.medie}</h6> : null }
            { true ? <h6 className="letterStyle">Nr. note: {props.nrNote}</h6> : null }
            { showSmallInfo ? <h6 className="letterStyle">Nr. absențe totale/nemotivate: <span style={{color: "green"}}>{props.nrAbsente}</span>/<span style={{color: "red"}}>{props.nrAbsenteNemotivate}</span></h6> : null }
            </div>
          </div>
        </div>
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", borderTop: "solid", paddingTop: "10px", paddingBottom: "10px", borderColor: "grey"}}>
          {!showSmallInfo ? 
              <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", paddingTop: "3px", paddingBottom: "3px"}}>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", padding: "3px 7px 3px 7px", gap: "15px"}} className="makeBorder">
                    <h6 className="letterStyle">Nr. absențe totale/nemotivate: <span style={{color: "green"}}>{props.nrAbsente}</span>/<span style={{color: "red"}}>{props.nrAbsenteNemotivate}</span></h6>
                </div>
              </div>
              :
              null
          }
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "3px", minWidth: "150px", justifyContent: "space-between", paddingBottom: "8px"}} className="makeBorder">
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <h6 className="letterStyle">Note</h6>
              {props.note.map(makeNotes)}
            </div>
            { props.hasNoteAuthority ? <DialogInfo idElev={props.idElev} nume={props.name} type="note" info={props.note} makeRefresh={props.makeRefresh}/> : null}
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "3px", minWidth: "150px", justifyContent: "space-between", paddingBottom: "8px"}} className="makeBorder">
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <h6 className="letterStyle">Absențe</h6>
              {props.absente.map(makeAbsente)}
            </div>
            { props.hasAbsenteAuthority ? <DialogInfo idElev={props.idElev} nume={props.name} type="absente" info={props.absente} makeRefresh={props.makeRefresh} /> : null}
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "5px"}} className="makeBorder">
            <h6 className="letterStyle">Statistici</h6>
            <div style={{display: "flex", justifyContent: "center", gap: "15px", flexWrap: "wrap"}}>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <p className="letterStyle">Medie: {props.medie} </p>
                <p className="letterStyle">Medie clasă: {props.medieClasa} </p>
                <p className="letterStyle">Medie școală: {props.medieScoala} </p>
              </div>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <p className="letterStyle">Nr. note: {props.nrNote} </p>
                <p className="letterStyle">Nr. mediu note clasă: {props.nrNoteClasa} </p>
                <p className="letterStyle">Nr. mediu note școală: {props.nrNoteScoala} </p>
              </div>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <p className="letterStyle">Nr. absențe: {props.nrAbsente} </p>
                <p className="letterStyle">Nr. mediu absențe clasă: {props.absenteClasa} </p>
                <p className="letterStyle">Nr. mediu absențe școală: {props.absenteScoala} </p>
              </div>
            </div>
            <p className="letterStyle">Nr. note până la atingerea nr. necesar de note: {props.noteNecesare}</p>
          </div>
        </div>
      </Collapse>
    </Card>
    </Container>
  );
}

export default MakeCard;
