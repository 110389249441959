import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import "../cssFiles/basicStyles.css";

export default function YouSureDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseNo = () => {
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
    props.makeCall();
  };

  return (
    <React.Fragment>
      {props.typeOfAction ? 
        <Button variant="text" onClick={handleClickOpen}>
            <RemoveCircleIcon style={{color: "#ce1126"}}/>
        </Button> :
        <Button variant="text" onClick={handleClickOpen}>
            <p className='letterStyle'>{props.text}</p>
        </Button>
      }
      <Dialog
        open={open}
        onClose={handleCloseNo}
      >
        <DialogTitle>
          {"Sunteți sigur de acțiunea pe care vreți să o faceți?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseNo}>Nu</Button>
          <Button onClick={handleClose} autoFocus>
            Da
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}