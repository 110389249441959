import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import DialogSchimbaNumeClasa from "./popUpSchimbaNumeClasa";
import DialogSchimbaDirig from "./popUpSchimbaDirig";

function MakeCardAdmin(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const manageOnClickElevi = () => {
    navigate("/eleviAdmin/" + props.id);
  }

  const manageOnClickOre = () => {
    navigate("/oreAdmin/" + props.id);
  }

  const manageOnClickOrar = () => {
    navigate("/orarClasa/" + props.id);
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "6px"}}>
            <h3 className="letterStyle">{props.name}</h3>
            {props.name != "Elevi fara clasa atribuita" ?
            <p className="letterStyle" style={{ marginTop: "7px" }}>
                {props.numedirig}
            </p> 
            : 
            null}
        </div>
        {props.name != "Elevi fara clasa atribuita" ? 
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            gap: "3px",
            paddingRight: "17px"
          }}
        >
            <div
                style={{display: "flex", flexWrap: "wrap", gap: "3px"}}>
                <Button onClick = {manageOnClickOre} variant="contained" color="success"><p className="letterStyle">Vezi ore</p></Button>
                <Button onClick = {manageOnClickElevi} variant="contained" color="success"><p className="letterStyle">Vezi elevi</p></Button>
            </div>
            <Button onClick = {manageOnClickOrar} variant="contained" color="success" style={{marginLeft: "7px", marginRight: "7px", paddingLeft: "68px", paddingRight: "68px"}}><p className="letterStyle">Vezi orar</p></Button>
            <DialogSchimbaDirig
                idClasa={props.id}
                refreshInfo={props.refreshInfo}
                name={props.numedirig}
            />
            <DialogSchimbaNumeClasa 
                idClasa={props.id}
                name={props.name}
                refreshInfo={props.refreshInfo}
            />
        </div>
        :
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            gap: "3px",
            paddingRight: "17px"
          }}
        >
            <div
                style={{display: "flex", flexWrap: "wrap", gap: "3px"}}>
                <Button onClick = {manageOnClickElevi} variant="contained" color="success"><p className="letterStyle">Vezi elevi</p></Button>
            </div>
        </div>
        }
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardAdmin;
