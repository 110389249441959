import React from "react";
import { Container } from "@mui/material";
import "../cssFiles/styles.css";
import "../cssFiles/loginStyle.css";
import "../cssFiles/basicStyles.css";
import { Box, Button, TextField } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import DialogReseteazaParola from "./popUpReseteazaParola";

function MakeLogin() {
  const [err, changeErr] = React.useState("");
  const cookies = new Cookies();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    /*
    try {
      const response = await axios.post(
        "http://localhost:4000/api/login", {
          email: event.target.email.value,
          password: event.target.password.value
        }
      );

      const token = response.data.token;

      const decoded = jwtDecode(token);

      changeErr("");
      cookies.set("_auth", token, {expires: new Date(decoded.exp * 1000)});

      navigate("/home");
    } catch (err) {
      changeErr(err.response.data);
      console.log("Error: ", err.response.data);
    }*/

    changeErr("Parloă sau email greșite.");
  };

  return (
    <Container className="loginBoxInfo" style={{display: "flex", justifyContent: "center"}}>
      <Box className="loginBoxInfo styledLoginBox" maxWidth='375px'>
        <h3 className="writingStyleTitle"> Login </h3>
        {err.length ? <div style={{display: "flex", justifyContent: "center", backgroundColor: "#FF2635", borderStyle: "solid", borderRadius: "8px", borderColor: "#FF404D", padding: "3px 2px 3px 2px"}}><p className="letterStyle">{err}</p></div> : null}
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Adresă Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Parolă"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Box className="boxWithText">
            <DialogReseteazaParola />
            <Button type="submit" className="buttonToSubmit" variant="contained">
              <p className="writignStyle centerSelf"> Login </p>
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default MakeLogin;
