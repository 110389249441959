import React from "react";
import Button from '@mui/material/Button';
import "../cssFiles/basicStyles.css"

function MakeCardMateriePopUp(props) {
    const [isOn, setIsOn] = React.useState(true);

    function makePress() {
        props.makeUpdate(props.id);
        setIsOn(isOn ^ 1);
    }

    return (
        <Button variant="contained" color={(props.faceora == isOn) ? "success" : "error"} onClick={makePress}><p className="letterStyle">{props.nume}</p></Button>
    );
}

export default MakeCardMateriePopUp;