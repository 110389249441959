import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import DialogSchimbaNumeClasa from "./popUpSchimbaNumeClasa";
import DialogSchimbaDirig from "./popUpSchimbaDirig";
import fontkit from "@pdf-lib/fontkit"

function MakeCardGoToProfesor() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const manageOnClickProfesori = () => {
    navigate("/profesoriAdmin");
  }

  const manageGenerateCondica = async () => {
    try {
      const response = await axios.post(
        "http://localhost:4000/api/getProfesoriAdmin", {
            token: cookies.get("_auth"),
          }
      );

      var aux = response.data.profi;

      aux.sort((a, b) => {
        let x = a.nume.toLowerCase();
        let y = b.nume.toLowerCase();
  
        if ( x == "null" || y == "null" ) {
          if ( y != "null" ) {
              return -1;
          }
          if ( x != "null" ) {
              return 1;
          }
          return 0;
        }
  
        if ( x > y ) {
          return 1;
        }
        if ( x < y ) {
          return -1;
        }
        return 0;
      });

      var profi = [];

      for ( var i = 0; i < aux.length; i++ ) {
        if ( aux[i].nume != "NULL" ) {
          profi.push(aux[i].nume);
        }
      }

      const url = 'http://localhost:4000/condicaPrezenta.pdf';
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
      
      const ubuntuFontBytes = await fetch(
        'http://localhost:4000/niceFont.ttf',
      ).then((res) => res.arrayBuffer());

      const finalPdf = await PDFDocument.load(existingPdfBytes);
      finalPdf.registerFontkit(fontkit);
      const goodFont = await finalPdf.embedFont(ubuntuFontBytes);

      const page = finalPdf.getPage(0);

      /// x = 72 ,98, 285, 327.5, 353.2, 405.2, 452.2, 497.2, 540 
      /// y = 652(nume si prenume) 654 cealalta
      /// yEnd = 40

      const y = 652;
      const x = [72, 98, 285, 327.5, 353.2, 405.2, 452.2, 497.2, 540];
      const yEnd = 30;

      var nrLines = 30;
      var pas = (y - yEnd) / nrLines;

      page.drawLine({
        start: { x: x[0], y: y},
        end: { x: x[2], y: y},
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75
      });

      page.drawLine({
        start: { x: x[3], y: y + 2},
        end: { x: x[8], y: y + 2},
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75
      });

      for ( var i = 0; i < 9; i++ ) {
        page.drawLine({
          start: { x: x[i], y: y + 2 * (i >= 3)},
          end: { x: x[i], y: y - pas * nrLines},
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75
        });
      }

      for ( var i = 1; i <= nrLines; i++ ) {
        page.drawLine({
          start: { x: x[0], y: y - pas * i},
          end: { x: x[2], y: y - pas * i},
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75
        });
        page.drawLine({
          start: { x: x[3], y: y  - pas * i},
          end: { x: x[8], y: y - pas * i},
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75
        });

        var textWidth = goodFont.widthOfTextAtSize(i.toString(), 10);
        page.drawText(i.toString(), {
          x: x[0] + (x[1] - x[0] - textWidth) / 2,
          y: y - pas * i + 6,
          font: goodFont,
          size: 10,
          color: rgb(0, 0, 0),
          lineHeight: 24,
          opacity: 0.75,
        });
        page.drawText(i.toString(), {
          x: x[3] + (x[4] - x[3] - textWidth) / 2,
          y: y - pas * i + 6,
          font: goodFont,
          size: 10,
          color: rgb(0, 0, 0),
          lineHeight: 24,
          opacity: 0.75,
        });
      }

      for ( var i = 0; i < profi.length; i++ ) {
        var textSize = 10;
        var textWidth = goodFont.widthOfTextAtSize(profi[i], textSize);

        while ( textWidth >= x[2] - x[1] - 10 ) {
          textSize--;
          textWidth = goodFont.widthOfTextAtSize(profi[i], textSize);
        }

        page.drawText(profi[i], {
          x: x[1] + 5,
          y: y - pas * (i + 1) + 6,
          font: goodFont,
          size: textSize,
          color: rgb(0, 0, 0),
          lineHeight: 24,
          opacity: 0.75,
        });
      }

      const pdfBytes = await finalPdf.save();

      const blob = new Blob([pdfBytes]);
      const fileUrl = window.URL.createObjectURL(blob);
  
      let alink = document.createElement("a");
      alink.href = fileUrl;
      alink.download = "CondicaPrezenta.pdf";
      alink.click();
    } catch(err) {
      cookies.remove("_auth");
      navigate("/");
    }
  }

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "6px"}}>
            <h3 className="letterStyle">Profesori</h3>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            gap: "3px",
            paddingRight: "17px"
          }}
        >
            <div style={{display: "flex", flexWrap: "wrap", gap: "3px", flexDirection: "column"}}>
                <Button onClick = {manageOnClickProfesori} variant="contained" color="success"><p className="letterStyle">Vezi profesori</p></Button>
                <Button onClick = {manageGenerateCondica} variant="contained" color="success"><p className="letterStyle">Genereaza condică</p></Button>
            </div>
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardGoToProfesor;
