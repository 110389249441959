import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import MakeCardOraPopUp from './boxOraInPopUp';

export default function DialogModificaMaterie(props) {
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [oreClasa, setOreClasa] = React.useState([]);

  async function handleClickOpen() {
    setOpen(true);
    async function testIntegrity() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getOreFast", {
                    token: cookies.get("_auth"),
                    idClasa: props.idClasa
                }
            );

            sortAlfabetic(response.data.ore);
          } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    await testIntegrity();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sortAlfabetic = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setOreClasa(aux);
  }

  async function updateInfo(idOra) {
    try {
        await axios.post(
            "http://localhost:4000/api/modificaTipOra", {
                token: cookies.get("_auth"),
                idOrar: props.idOrar,
                idOra: idOra
            }
        );

        props.refreshInfo();
        handleClose();
      } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  }

  function makeCardOra(ora) {
    return (
        <MakeCardOraPopUp
            key = {ora.id}
            id = {ora.id}
            nume = {ora.nume}
            makeUpdate = {updateInfo}
        />
    )
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} color='success' variant='contained'  style={{paddingLeft: "65px", paddingRight: "65px"}}>Modifică materie</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Alege materie nouă</DialogTitle>
        <DialogContent dividers={true}>
        <div style={{display: "flex", flexDirection: "column", gap: "3px"}}>
          <MakeCardOraPopUp 
            id = {1}
            nume = {'Liber'}
            makeUpdate = {updateInfo}
          />
          {oreClasa.map(makeCardOra)}
        </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}