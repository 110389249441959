import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MakeCardProfPentruElev from "./colidbleBoxProf";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import MakeCardElevDirector from "./boxElevDirector";
import MakeCardOra from "./boxOra";
import MakeCardElevAdmin from "./boxElevAdmin";

function InfotainmentEleviAdmin(props) {
  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const navigate = useNavigate();
  const {id} = useParams();
  const [elevi, setElevi] = React.useState([]);

  const sortAlfabeticVar = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x == "null" || y == "null" ) {
        if ( y != "null" ) {
          return -1;
        }
        if ( x != "null" ) {
          return 1;
        }
        return 0;
      }

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    setElevi(aux);
  }

  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
      if ( !cookies.get("_auth") ) {
          navigate("/");
      }

      async function testIntegrity() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getEleviAdmin", {
                    token: cookies.get("_auth"),
                    idClasa: id
                }
            );

            var x = response.data.elevi;
            sortAlfabeticVar(x);
        } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    if ( oneTime ) {
      testIntegrity();
      setOneTime(false);
    }
    }, [cookies, navigate]);

    async function refreshOre() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getEleviAdmin", {
                    token: cookies.get("_auth"),
                    idClasa: id
                }
            );

            var x = response.data.elevi;
            sortAlfabeticVar(x);
        } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    function makeCardForElev(elev) {
      return (
        <MakeCardElevAdmin
            key={elev.id}
            id={elev.id}
            name={elev.nume}
            refreshInfo={refreshOre}
            adresa={elev.adresa}
            nrmatricol={elev.nrmatricol}
            mail={elev.mail}
            mailelev={elev.mailelev}
        />
      );
  }

  const adaugaElev = async () => {
    try {
      await axios.post(
          "http://localhost:4000/api/adaugaElev", {
              token: cookies.get("_auth"),
          }
      );

      refreshOre();
  } catch(err) {
      cookies.remove("_auth");
      navigate("/");
  }
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "13px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
          <Button onClick={adaugaElev} variant="contained">Adaugă elev</Button>
        </div>
        {elevi.map(makeCardForElev)}
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "end", paddingTop: "5px", borderTopStyle: "solid", borderTopColor: "grey", marginTop: "13px"}}>
          <Button onClick={adaugaElev} variant="contained">Adaugă elev</Button>
        </div>
    </Container>
  );
}

export default InfotainmentEleviAdmin;
