import * as React from "react";
import "../cssFiles/basicStyles.css";
import "../cssFiles/styles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MakeCardAbsenta from "./boxAbsente";
import Cookies from "universal-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import YouSureDialogForPrezenta from "./popUpYouSureDialogForPrezenta";
import { useParams } from "react-router-dom";

function InfotainmentPrezenta(props) {
  const v = new Map();
  const navigate = useNavigate();
  const cookies = React.useMemo(() => {return new Cookies()}, []);
  const [elevi, changeElevi] = React.useState([]);
  const [numeMaterie, changeNumeMaterie] = React.useState("");
  const {id} = useParams();

  const makeCallback = (id) => {
    v.set(id, false);
  }

  const changeState = (id, val) => {
    v.set(id, val);
  }

  const sortAlfabetic = (x) => {
    var aux = x;

    aux.sort((a, b) => {
      let x = a.nume.toLowerCase();
      let y = b.nume.toLowerCase();

      if ( x > y ) {
        return 1;
      }
      if ( x < y ) {
        return -1;
      }
      return 0;
    });

    changeElevi(aux);
  }

  const [oneTime, setOneTime] = React.useState(true);

  React.useEffect(() => {
    if ( !cookies.get("_auth") ) {
        navigate("/");
    }

    async function testIntegrity() {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/getPrezenta", {
                    token: cookies.get("_auth"),
                    idOra: id
                }
            );

            changeNumeMaterie(response.data.materie);
            sortAlfabetic(response.data.elevi);
          } catch(err) {
            cookies.remove("_auth");
            navigate("/");
        }
    }

    if ( oneTime ) {
      testIntegrity();
      setOneTime(false);
    }
  }, [cookies, navigate]);

  const puneAbsente = async () => {
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yy = date.getFullYear();
    const currDate = dd + "." + mm + "." + yy;

    console.log(currDate);

    for ( let [key, value] of v ) {
      if ( value ) {
        try {
          await axios.post(
            "http://localhost:4000/api/addAbsenta", {
              token: cookies.get("_auth"),
              idElev: key,
              nume: numeMaterie,
              data: currDate
            }
          )
        } catch(err) {
          cookies.remove("_auth");
          navigate("/");
        }
      }
    }

    navigate(-1);
  }

  const makeElev = (elev) => {
    return (
      <MakeCardAbsenta key={elev.id} id={elev.id} init={makeCallback} callParent={changeState} name={elev.nume} />
    );
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Container style={{marginTop: "5px"}}>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "5px", borderBottomStyle: "solid", borderBottomColor: "grey", marginBottom: "13px"}}>
          <Button onClick={goBack} variant="contained"><ArrowBackIosNewIcon color="white"/> Înapoi</Button>
          <YouSureDialogForPrezenta makeCall={puneAbsente} text = "Ok" />
        </div>
        {elevi.map(makeElev)}
        <div style={{display: "flex", justifyContent: "end", borderTopStyle: "solid", borderTopColor: "grey", paddingTop: "5px"}}>
          <YouSureDialogForPrezenta makeCall={puneAbsente} text = "Ok" />
        </div>
    </Container>
  );
}

export default InfotainmentPrezenta;
