import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "../cssFiles/basicStyles.css";
import { Box, TextField } from "@mui/material";
import "../cssFiles/loginStyle.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function DialogContactParinte(props) { ///props.id = idOra / props.id = -1 => director/diriginte
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  function handleClickOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        await axios.post("http://localhost:4000/api/contactParinte", {
            token: cookies.get("_auth"),
            idOra: props.idOra,
            idElev: props.idElev,
            text: event.target.text.value
        });

        handleClose();
    } catch(err) {
        cookies.remove("_auth");
        navigate("/");
    }
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} variant='contained' color='success'>Contactează părinte</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        fullScreen={true}
        TransitionComponent={Transition}
      >
        <DialogTitle component="h3" className='letterStyle' style={{display: "flex", justifyContent: "center"}}>Contactează părinte</DialogTitle>
        <DialogContent dividers={true}>
          <Box component="form" onSubmit={handleSubmit}>
              <div style={{display: "flex", gap: "3px"}}>
                  <TextField
                      fullWidth
                      variant="outlined"
                      label="Text mesaj"
                      name="text"
                      multiline
                      maxRows={4}
                      rows={10}
                      autoFocus
                  />
              </div>
              <Box style={{display: "flex", gap: "3px", justifyContent: "end", marginTop: "5px"}}>
                  <Button onClick={handleClose} variant='text'>Închide</Button>
                  <Button type="submit" variant="contained"> Trimite </Button>
              </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}