import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "../cssFiles/basicStyles.css";
import { Container } from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

function MakeCardProgram(props) {
  const navigate = useNavigate();

  return (
    <Container>
    <Card
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "10px",
        marginRight: "10px",
        maxWidth: "90vw",
        borderStyle: "solid",
        borderColor: "grey",
        padding: "5px",
        borderRadius: "25px"
      }}
    >
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap"}}>
          <h3 className="letterStyle">{props.inceputOra < 10 ? "0" : null}{props.inceputOra}:{props.inceputMinut < 10 ? "0" : null}{props.inceputMinut} - {props.sfarsitOra < 10 ? "0" : null}{props.sfarsitOra}:{props.sfarsitMinut < 10 ? "0" : null}{props.sfarsitMinut} {props.nume} {props.clasa}</h3>
          <h5 className="letterStyle" style={{paddingLeft: "8px", paddingTop: "5px"}}>{props.numeSala != "NONE" ? props.numeSala : null} {props.scoala}</h5>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "15px"
            }}
          >
          </div>
        </div>
      </div>
    </Card>
    </Container>
  );
}

export default MakeCardProgram;
